import React, { useEffect, useState } from "react";
import isEmpty from "../../../components/isEmpty";

//css
import "./DashboardLoading.css";

import DefaultHeader from "./DefaultHeader";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//imgs
import logo_band from "../../../assets/img/band_logo.png";
import printing_studio_logo from "../../../assets/img/printing_studio_logo.png";
import music_store_logo from "../../../assets/img/music_store_logo.png";
import shop_logo from "../../../assets/img/shop_logo.png";

//DashboardLoading
function DashboardLoading(props) {
  //VIEW
  return (
    <div className="dashboard-loading">
      <DefaultHeader handleLogout={handleLogout} />
      {/* {props.isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )} */}
      {props.isApiWorking && (
        <div className="container-api">
          <div className="loading-container">
            <div className="item item-1">
              <img src={logo_band} alt="" />
            </div>
            <div className="item item-2">
              <img src={printing_studio_logo} alt="" />
            </div>
            <div className="item item-3">
              <img src={music_store_logo} alt="" />
            </div>
            <div className="item item-4">
              <img src={shop_logo} alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );

  //Logout Request
  function handleLogout() {
    if (window.confirm("Are you sure you want to logout?")) {
      props.handleLogout();
    }
  }
}

export default DashboardLoading;
