import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import useLocalStorage from "./components/useLocalStorage";
//
import { AppContext } from "./libs/contextLib";
import ErrorBoundary from "./components/ErrorBoundary";
import Routes from "./Routes";

//App Class
function App() {
  //user route is authenticated?
  const [isAuthenticated, userHasAuthenticated] = useLocalStorage(
    "authED_S_User",
    false
  );
  //login isremembered
  const [shouldRememberLogin, setRememberLogin] = useLocalStorage(
    "remember_edshop_login",
    true
  );
  //user
  const [user, setUser] = useLocalStorage("ed_login_user", null);

  //ViewDidLoad
  useEffect(() => {
    // handleLogout();
    //authenticate user
    if (shouldRememberLogin) {
      authenticateUser();
    }
  }, []);

  //API: Cognito user logged in
  async function authenticateUser() {
    try {
      //✅Authentication success -> user logged in
      await Auth.currentSession();
      userHasAuthenticated(true);
      console.log("USER_AUTH_SUCCESS");
    } catch (error) {
      //✅Authentication error -> user failed to login
      if (error !== "No current user") {
        alert("User authentication failed", error);
      }
      console.log("USER_AUTH_FAILED", error);
      userHasAuthenticated(false);
    }
  }

  //API: Logout from Cognito
  async function handleLogout() {
    try {
      //✅Logout success -> go to login
      await Auth.signOut();
      userHasAuthenticated(false);
      window.location.href = "/welcome";
      //remove user
      setUser(null);
    } catch (error) {
      //❌Logout error -> show alert
      alert("Logout error", error);
    }
  }

  //VIEW
  return (
    <div>
      <ErrorBoundary>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <Routes handleLogout={handleLogout} />
        </AppContext.Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
