import React from "react";
import { Switch } from "react-router-dom";

//ROUTES
//Unauth
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Login from "./containers/unauth/login/Login";
import Register from "./containers/unauth/register/Register";
import ForgotPass from "./containers/unauth/forgotpass/ForgotPass";
import ResetPass from "./containers/unauth/resetpass/ResetPass";
import Validate from "./containers/unauth/validate/Validate";
import Welcome from "./containers/unauth/welcome/Welcome";

//->error route
import NotFound from "./containers/notfound/NotFound";

//Auth
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Dashboard from "./containers/auth/dashboard/Dashboard";

//ROUTES class
function Routes(props) {
  //
  //CALLBACK: Logout user
  function handleLogout() {
    props.handleLogout();
  }

  //VIEW
  return (
    <Switch>
      {/* UnauthenticatedRoute */}
      <UnauthenticatedRoute path="/">
        <Welcome />
      </UnauthenticatedRoute>
      {/* <UnauthenticatedRoute path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/login:id">
        <Login />
      </UnauthenticatedRoute> */}
      <UnauthenticatedRoute path="/register">
        <Register />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/resetpass">
        <ResetPass />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/forgotpass">
        <ForgotPass />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/validate:id">
        <Validate />
      </UnauthenticatedRoute>
      {/* AuthenticatedRoute */}
      <AuthenticatedRoute path="/">
        {/* <Register/> */}
        <Dashboard handleLogout={handleLogout} />
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <UnauthenticatedRoute>
        <NotFound title={"Unknown error"} />
      </UnauthenticatedRoute>
    </Switch>
  );
}

export default Routes;
