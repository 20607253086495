import axios from "axios";

//GET_ED
export const getED = async (album_id) => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await get_ed_req(album_id);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_ed_req = async (album_id) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/ed/get_ed" + "?" + `album_id=${album_id}`;

    axios
      .get(URL)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//SEARCH_EDs
export const searchEDs = async (queryParameters) => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await get_search_eds_req(queryParameters);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_search_eds_req = async (queryParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL = apiEndpoint + "/prod" + "/ed/search_ed" + "?" + queryParameters;

    axios
      .post(URL, null)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
