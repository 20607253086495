import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Linkify from "react-linkify";
import { getUserDetails } from "../../../utils/apis/api/auth_api_user";
//CSS
import "./Account.css";

//JS
import EditAccount from "./00EditAccount/EditAccount";
//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//imgs
import logo_band from "../../../assets/img/band_logo.png";
import printing_studio_logo from "../../../assets/img/printing_studio_logo.png";
import music_store_logo from "../../../assets/img/music_store_logo.png";
import shop_logo from "../../../assets/img/shop_logo.png";

//LINK
//✅logged in
//https://edshop.songdrop.uk/account
//Account
function Account(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState(false);

  //props
  const [user, setUser] = useState(null);
  //->EDITING_STAT
  const [isEditing, setIsEditing] = useState(true);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "EDShop | Account";
    //api
    getUser();
  }, []);

  //API: Cognito user logged in
  async function getUser() {
    try {
      //get user details
      const result = await getUserDetails();
      //SUCCESS
      const user = result["user"];
      console.log("USER_GET_SUCCESS", user);
      setApiWorking(false);
      setUser(user);
    } catch (error) {
      //ERROR
      console.log("USER_GET_ERROR", error, error.message);
      setApiError(error.message);
      setApiWorking(false);
    }
  }

  //VIEW
  return (
    <div className="account-body">
      {/* API_WORKING */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {/* API_ERROR */}
      {!isEmpty(apiError) && (
        <div
          className="error"
          style={{ color: "red", textAlign: "center", display: "block" }}
        >
          {apiError}
        </div>
      )}
      {/* ✅API_USER_FOUND */}
      {!isEmpty(user) && (
        <div className="user">
          <div className="header">
            <img
              src={
                isEmpty(user.profile_img_url)
                  ? returnAccTypeImg(user.usertype)
                  : user.profile_img_url
              }
            />
            {/* USER_HEADER_DETAILS */}
            <div className="user_name">
              <div className="name">{user.name}</div>
              <div className="type">{returnAccTypeString(user.usertype)}</div>
              <div className="username">{user.username}</div>
              {!isEmpty(user.website) && (
                <a className="website">
                  <i className="fa fa-globe" />
                  {user.website}
                </a>
              )}
              {/* EDIT_BTN */}
              <button
                className="edit_btn"
                onClick={() => setIsEditing(!isEditing)}
              >
                <i className="fa fa-edit" />
                EDIT
              </button>
            </div>
          </div>
          {/* NO_EDIT: USER_ABOUT */}
          {!isEditing && (
            <div className="about">
              <div className="title">ABOUT:</div>
              <Linkify>
                <p className="text">{user.about}</p>
                <br />
              </Linkify>
            </div>
          )}
          {/* ON_EDIT: EDIT_USER_FORM */}
          {isEditing && (
            <div className="edit">
              <EditAccount user={user} />
            </div>
          )}
          {/* MEMBER_SINCE */}
          <div className="member">
            <p>Location: {user.location}</p>
            <p>Member since: {user.created_at}</p>
          </div>
        </div>
      )}
    </div>
  );

  //->STRING_ACC_TYPE
  function returnAccTypeString(account_type) {
    switch (account_type) {
      case "0":
        return "Artist";
      case "1":
        return "Printing Studio";
      case "2":
        return "Record Store";
      case "3":
        return "Shop";
      default:
        return "user";
    }
  }

  //->IMG_ACC_TYPE
  function returnAccTypeImg(account_type) {
    switch (account_type) {
      case "0":
        return logo_band;
      case "1":
        return printing_studio_logo;
      case "2":
        return music_store_logo;
      case "3":
        return shop_logo;
      default:
        return "";
    }
  }
}

export default Account;
