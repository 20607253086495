import { Auth } from "aws-amplify";
const AwsConfig = require("../AWSConfig");

//SIGN_UP
export const signUp = async (email, password, attribute_list) => {
  return new Promise((resolve, reject) => {
    AwsConfig.initAWS();
    AwsConfig.getUserPool().signUp(
      email,
      password,
      AwsConfig.getCognitoAttributeList(attribute_list),
      null,
      function (err, result) {
        if (err) {
          reject(err);
        }
        if (result) {
          resolve(result);
        }
      }
    );
  });
};

//VALIDATE_ACCOUNT
export const validate = async (email, code) => {
  return new Promise((resolve, reject) => {
    AwsConfig.getCognitoUser(email).confirmRegistration(
      code,
      true,
      (err, result) => {
        if (err) {
          reject(err);
        }
        if (result) {
          resolve(result);
        }
      }
    );
  });
};

//RESEND_VALIDATION_CODE
export const resendCode = async (email) => {
  return new Promise((resolve, reject) => {
    AwsConfig.getCognitoUser(email).resendConfirmationCode((err, result) => {
      if (err) {
        reject(err);
      }
      if (result) {
        resolve(result);
      }
    });
  });
};

//FORGOT_PASSWORD
export const forgotPass = async (email) => {
  return new Promise((resolve, reject) => {
    AwsConfig.getCognitoUser(email).forgotPassword({
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
};

//RESET_PASSWORD
export const resetPass = async (email, confirmationCode, password) => {
  return new Promise((resolve, reject) => {
    AwsConfig.getCognitoUser(email).confirmPassword(
      confirmationCode,
      password,
      {
        onFailure(err) {
          reject(err);
        },
        onSuccess() {
          resolve("PASSWORD_RESET_SUCCESS");
        },
      }
    );
  });
};

//USER_CONFIRMED_CHECK
export const isConfirmed = async (email) => {
  // console.log(AwsConfig.getCognitoUser(email).getUserPool);
  return AwsConfig.getCognitoUser(email).isConfirmed;
};

//SIGN_IN
export const signIn = async (email, password) => {
  return new Promise((resolve, reject) => {
    Auth.signIn(email, password)
      .then((data) => {
        console.log(data)
        resolve("USER_LOGGED_IN");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
