import React, { useEffect, useState } from "react";

import isEmpty from "../../../components/isEmpty";

//JS
import Footer from "../../../components/Footer/Footer";

//css
import "./Welcome.css";

//icons+about
import logoIcon from "../../../assets/img/songdrop-logo-black.png";
import appstoreIcon from "../../../assets/img/appstore-logo.png";
import about from "../../../assets/about/about.json";
import Background from "../Background";

const youtubelink = "https://youtube.com/embed/8E9XiLhVvJQ";
//NO-RERENDER
const Bcg = React.memo((props) => {
  return <Background />;
});

//LINK
//👤 not logged in
//https://edshop.songdrop.uk/welcome
//Welcome
function Welcome() {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="welcome-container">
      {/* <Bcg /> */}
      <div className="body">
        <a
          className="coming-soon"
          href="https://www.mosaic-boardprint.com"
          alt=""
        >
          {" "}
          <i className="fa fa-print" /> Order ED(Electronically Distributed){" "}
          <i className="fa fa-chevron-right" />
        </a>
        <iframe
          width="100%"
          height="100%"
          src={youtubelink}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      {/* COMPANY_FOOTER */}
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );
}

export default Welcome;
