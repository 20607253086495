import React, { useState, useEffect } from "react";
import Select from "react-select";
import { listPrinters } from "../../../../../utils/apis/api/auth_api_order";

//CategoryAsyncSelect class
function PrinterAsyncSelect(props) {
  //height of element
  const selectHeight = 30;

  //Category
  const [selectedPrintingStudio, setSelectedPrintingStudio] = useState(
    props.selectedPrintingStudio ?? null
  );

  const [printingOptions, setPrintingOptions] = useState([]);
  const [isApiLoading, setApiLoading] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //api
    // getStudioOptionsAsync();
  }, []);

  //Api call: get all studio partners
  async function getStudioOptionsAsync() {
    setApiLoading(true);
    try {
      //✅SUCCESS
      let result = await listPrinters("");
      let printers = result["printers"].map((studio) => ({
        label: studio["name"] + "," + studio["location"],
        value: studio,
      }));
      setPrintingOptions(printers);
      setApiLoading(false);
      //🚫ERROR
    } catch (error) {
      setApiLoading(false);
      console.log(error.message);
    }
  }

  //VIEW
  return (
    <Select
      styles={getStyle()}
      value={selectedPrintingStudio}
      options={printingOptions}
      onFocus={getStudioOptionsAsync}
      onChange={_onChange}
      isLoading={isApiLoading}
      isMulti={false}
      placeholder="Select a printing studio"
    />
  );

  //GET: style of component
  function getStyle() {
    return {
      control: (base) => ({
        ...base,
        minHeight: selectHeight,
      }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: 4,
      }),
      clearIndicator: (base) => ({
        ...base,
        padding: 4,
      }),
      multiValue: (base) => ({
        ...base,
        backgroundColor: variables.colorPrimaryLighter,
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "0px 6px",
      }),
      input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
      }),
    };
  }

  //OnChange
  function _onChange(object, { action }) {
    switch (action) {
      case "input-change":
        if (object) selectValue(object);
        return;
      case "menu-close":
        return;
      case "clear":
        return;
      case "select-option":
        if (object) selectValue(object);
        return;
      default:
        return;
    }
  }

  //Select value
  function selectValue(object) {
    setSelectedPrintingStudio(object);
    props.selectedValue(object.value);
  }
}

export default PrinterAsyncSelect;
