import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";

//CSS
import "./PayPalBusiness.css";
//about
import paypal_business from "../../../../assets/json/paypal_business.json";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//PayPalBusiness-EDIT
function PayPalBusiness(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  //ViewDidLoad
  useEffect(() => {}, []);

  const [paypal_id, setPayPalAttr] = useState(props.user.paypalId);

  //API_EDIT_DATA
  const updateData = async (event) => {
    event.preventDefault();
    setApiWorking(true);
    //API
    var queryParams = [];
    queryParams.push(`paypal_id=${paypal_id}`);
    const qParameters = queryParams.join("&");
    setTimeout(function () {
      console.log("ACCOUNT_UPDATED");
      setApiWorking(false);
      window.location.href = "/account?edit=1";
    }, 2000);
  };

  //VIEW
  return (
    <div className="paypal_box">
      <div>PayPal Business Seller Id:</div>
      <div className="input_row">
        {!isEmpty(paypal_id) && <i className="fa fa-check-circle" />}
        <input
          placeholder="Add seller id"
          value={paypal_id}
          onChange={(e) => setPayPalAttr(e.target.value)}
        />
      </div>
      <div className="paypal_id">
        <a
          style={{ paddingLeft: isEmpty(paypal_id) === true ? "0px" : "12px" }}
          className="edit-btn"
          href={paypal_business.paypal_business_credentials_url}
          target="_blank"
        >
          <i className="fa fa-info-circle" />
          How to get seller id?
        </a>
      </div>
      <div className="save">
        <br />
        {/* API_WORKING */}
        {isApiWorking && (
          <div className="stat-load">
            <div className="stat-loader">
              <i className={apiFaIcon} />
            </div>
          </div>
        )}
        {/* API_NOT_WORKING */}
        {!isApiWorking && (
          <button className="update-acc-btn" onClick={(e) => updateData(e)}>
            Save
          </button>
        )}
      </div>
    </div>
  );

  //ACCOUNT_TYPES
  function returnAccTypeString(account_type) {
    switch (account_type) {
      case "0":
        return "Artist";
      case "1":
        return "Printing Studio";
      case "2":
        return "Record Store";
      case "3":
        return "Shop";
      default:
        return "user";
    }
  }
}

export default PayPalBusiness;
