import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";

//CSS
import "./PostageSetter.css";

//RefundSetter
function ReturnSetter(props) {
  //API
  function changeCanReturn(can_return) {
    props.updateReturnPolicy("can_return", can_return);
  }

  function changeReturnPaidBy(return_paid_by) {
    props.updateReturnPolicy("return_paid_by", return_paid_by);
  }

  function changeCanReturnInDays(can_return_in_days) {
    props.updateReturnPolicy("can_return_in_days", can_return_in_days);
  }

  //ViewDidLoad
  useEffect(() => {
    txtChar = document.getElementById("txtChar");
    txtChar.addEventListener("keypress", isNumberKey, false);
  }, []);

  //VIEW
  return (
    <div className="postage">
      <div className="p_title">Returns</div>
      <div className="checkbox">
        <div className="checkbox-wrapper">
          <input
            type="checkbox"
            checked={props.return_policy.can_return === 0}
            onChange={(e) => changeCanReturn(0)}
          />
        </div>
        <p
          style={{
            fontWeight:
              props.return_policy.can_return === 0 ? "bold" : "normal",
          }}
        >
          No returns accepted
        </p>
      </div>
      <div className="checkbox">
        <div className="checkbox-wrapper">
          <input
            type="checkbox"
            checked={props.return_policy.can_return !== 0}
            onChange={(e) => changeCanReturn(1)}
          />
        </div>
        <div
          style={{
            fontWeight:
              props.return_policy.can_return !== 0 ? "bold" : "normal",
          }}
        >
          <p>Accept returns - </p>
          <select
            value={props.return_policy.can_return_in_days}
            onChange={(e) => changeCanReturnInDays(e.target.value)}
          >
            <option value="30">30</option>
            <option value="60">60</option>
          </select>
          <p>days</p>
          <select
            value={props.return_policy.return_paid_by}
            onChange={(e) => changeReturnPaidBy(e.target.value)}
          >
            <option value="seller">Seller</option>
            <option value="buyer">Buyer</option>
          </select>
          <p>pays return postage</p>
        </div>
      </div>
    </div>
  );

  function isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 46 || (charCode > 31 && (charCode < 48 || charCode > 57))) {
      evt.preventDefault();
      return false;
    }
    return true;
  }
}

export default ReturnSetter;
