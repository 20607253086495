import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import ImageSlider from "./item/ImageSlider";
import PrinterAsyncSelect from "./printing_studio_search/PrinterAsyncSelect";
import { gbpCurrency } from "../../../../components/currencyFormatter";
import { orderItem } from "../../../../utils/apis/api/auth_api_order";
import { fileToS3 } from "../../../../utils/apis/s3/UploadImage";
import { getED } from "../../../../utils/apis/api/public";

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
//icons
import cdLogo from "../../../../assets/img/ic_compactdisk.png";

//CSS
import "./EDItem.css";

//JS
import Postage from "../3postage/Postage";
import SaveQrDesign from "./SaveQrDesign";

//LINK
//✅logged in
//https://edshop.songdrop.uk/
//EDItem
function EDItem(props) {
  //CD_URL_IS_ENABLED -> show cd merch external link if available
  const cdURLenabled = true;

  //ui width
  const windowMargin = 26;
  const [containerWidth, setContainerWidth] = useState(
    Math.min(300, window.innerWidth - windowMargin - 12)
  );

  //API
  const [apiError, setApiError] = useState("");
  const [isApiWorking, setApiWorking] = useState(true);
  const [isApiOrdering, setApiOrdering] = useState(false);
  const [album, setAlbum] = useState(null);

  //ORDER_PROPS: style, printing_studio,quantity+price => make order(generate_order => pay paypal)
  const [selected_style, setSelectedStyle] = useState(0);
  const [selected_printer, setSelectedPrinter] = useState(null);
  //
  const [selected_price, setSelectedPrice] = useState(null);
  const [selected_quantity, setSelectedQuantity] = useState(0);
  const [total_price, setTotalPrice] = useState(0);

  const [frontImgData, setFrontImgData] = useState(null);
  const [backImgData, setBackImgData] = useState(null);

  //ViewDidLoad
  useEffect(() => {
    //album_id to order
    var album_id = window.location.pathname.split("/")[2];
    setSelectedPrinter(null);
    setSelectedQuantity(0);
    setTotalPrice(0);
    //API -> no albumId -> fetch default list of EDs for SALE
    if (!isEmpty(album_id)) {
      getAlbumWithId(album_id);
    }
    //resize windows width
    window.addEventListener("resize", updateWindowDimensions);
  }, []);

  //API
  async function getAlbumWithId(album_id) {
    //
    setApiWorking(true);
    setApiError("");

    try {
      let result = await getED(album_id);
      if (result === "ALBUM_NOT_FOUND") {
        //❌Error: ALBUM NOT FOUND
        throw new Error("ALBUM NOT FOUND");
      } else {
        //✅SUCCESS
        setAlbum(result);
        setApiWorking(false);
      }
    } catch (error) {
      console.log(error);
      //❌Error: Validation falied
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //ORDER_ID
  async function orderBtnPressed() {
    //CREATE_CHECKOUT_LINK
    //->GO TO LINK
    setApiOrdering(true);
    setApiError("");

    //get parameters
    //upload two images
    //create draft

    try {
      var frontURL = await fileToS3(
        frontImgData.img,
        `edshop/${props.user.user_id}`,
        frontImgData.filename
      );
      var backURL = await fileToS3(
        backImgData.img,
        `edshop/${props.user.user_id}`,
        backImgData.filename
      );
      var bodyParams = [];
      bodyParams.push(`front_url=${frontURL}`);
      bodyParams.push(`back_url=${backURL}`);
      bodyParams.push(`selected_order=${JSON.stringify(selected_price)}`);
      bodyParams.push(
        `product_name=ED Style ${selected_style + 1} - ${selected_quantity}`
      );
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      const response = await orderItem(bParameters);
      var order_id = response["order_id"];
      //->go to checkout
      window.location.href = `/checkout?id=${order_id}`;
      setApiWorking(false);
    } catch (error) {
      console.log(error);
      //❌Error: Validation falied
      setApiWorking(false);
      setApiOrdering(false);
      setApiError(error.message);
      alert(error.message);
    }
  }

  //
  //UPDATE: SELECTED_STUDIO_TO_ORDER
  function selectedStudio(printing_studio) {
    setSelectedPrinter(printing_studio);
    //reset selected quantity
    setSelectedQuantity(0);
  }

  //QUANTITY
  function selectQuantity(selected_quantity) {
    setSelectedQuantity(selected_quantity);
    //SELECTED_QUANTITY_IS_NULL->reset unit prices
    if (isEmpty(selected_quantity)) {
      setTotalPrice(0);
      return;
    }
    //CALCULATE_TOTAL_PRICE
    calculateUnitPriceTotal(selected_quantity);
  }

  //QUANTIFY
  function selectPrice(selected_price) {
    setSelectedPrice(selected_price);
    selectQuantity(selected_price.quantity);
  }

  //VIEW
  return (
    <div className="ed_item">
      {/* API_LOADING_CONTENT */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {/* API_ERROR */}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="_error">{apiError}</div>
      )}
      {/* ✅API_CONTENT_FOUND */}
      {!isApiWorking && !isEmpty(album) && (
        <div>
          <div
            className="ed-holder"
            style={{
              width: `${containerWidth}px`,
              height: `${containerWidth}px`,
            }}
          >
            <div
              className="_ed-holder"
              style={{
                width: `${containerWidth}px`,
                height: `${containerWidth}px`,
              }}
            >
              <SaveQrDesign
                height={300}
                width={300}
                fontSize={12}
                album={album}
                isBack={true}
                edStyle={parseInt(selected_style)}
                filename={`ed_style${selected_style}-back.png`}
                escapeImgData={setBackImgData}
              />
              <SaveQrDesign
                height={300}
                width={300}
                fontSize={12}
                album={album}
                isBack={false}
                edStyle={parseInt(selected_style)}
                filename={`ed_style${selected_style}-front.png`}
                escapeImgData={setFrontImgData}
              />
            </div>
            <div
              className="white_bcg"
              style={{
                width: `${containerWidth}px`,
                height: `${containerWidth}px`,
              }}
            ></div>
            {/* ED_SELECTOR_IMAGE_SLIDER */}
            <div
              className="img-holder"
              style={{
                width: `${containerWidth}px`,
                height: `${containerWidth}px`,
              }}
            >
              <ImageSlider
                width={containerWidth}
                height={containerWidth}
                items={getSliderItems()}
                selected_style={selected_style}
                changedIndex={(e) => onChangeEDStyle(e)}
              />
            </div>
          </div>

          {/* ED_ITEM_DESCRIPTION_TEXT */}
          <div className="title-holder">
            <a className="title" href={`/ordered/${album.album_id}`}>
              {album.title}
              {album.explicit == 1 ? "🅴" : ""} - {album.artist}
            </a>
            <div className="year">{album.genres}</div>
            <div className="year">
              {setYear(album.released_at)} - {album.country}
            </div>
            <div className="year">{album.tag}</div>
            <div className="">{album.location}</div>
            <br />
            <div className="order_style">
              <p>
                <strong>Style:</strong>
              </p>
              <select
                value={selected_style}
                onChange={(e) => onChangeEDStyle(e.target.value)}
              >
                <option value={0}>ED STYLE 1</option>
                <option value={1}>ED STYLE 2</option>
              </select>
            </div>
            <div className="order_place">
              <p>
                <strong>Printing Studio:</strong>
              </p>
              <PrinterAsyncSelect selectedValue={selectedStudio} />
              <p>
                If you would like to order custom stickers or any printing
                material, please visit our partner's website:
                {!isEmpty(selected_printer) &&
                  !isEmpty(selected_printer.website) && (
                    <a href={selected_printer.website}>
                      {selected_printer.website}
                    </a>
                  )}
              </p>
            </div>
            <div className="order_quantity">
              <p>
                <strong>Quantity:</strong>
              </p>
              <div className="table_container">
                {!isEmpty(selected_printer) &&
                  !isEmpty(
                    selected_printer["seller_prices"]["ed_prices"][
                      "description"
                    ]
                  ) && (
                    <div style={{ paddingLeft: "4px" }}>
                      <i className="fa fa-info-circle" />
                      {
                        selected_printer["seller_prices"]["ed_prices"][
                          "description"
                        ]
                      }
                    </div>
                  )}
                <table className="price_table">
                  {!isEmpty(selected_printer) &&
                    !isEmpty(
                      selected_printer["seller_prices"]["ed_prices"][
                        "item_prices"
                      ]
                    ) && (
                      <thead>
                        <tr>
                          <td style={{ width: "20px" }}></td>
                          <td>Qt</td>
                          <td>Price</td>
                          <td>Unit Price</td>
                        </tr>
                      </thead>
                    )}
                  {selectableQuantityAndPrices()}
                </table>
              </div>
            </div>
            <div className="order_price_total">
              <p>
                <strong>Total Price:</strong>
              </p>
              <p>
                <strong>{gbpCurrency(total_price)}+VAT</strong>
              </p>
            </div>
            <br />
            <div className="make-order">
              {isApiOrdering === false && (
                <button
                  className="order-btn"
                  onClick={() => orderBtnPressed(album.album_id)}
                  disabled={
                    isEmpty(selected_printer) || isEmpty(selected_quantity)
                  }
                >
                  Place Order for ED
                </button>
              )}
              {/* -->API_IS_WORKING_CURRENTLY */}
              {isApiOrdering === true && (
                <button
                  className="btn btn_sign_in btn-primary"
                  style={{ backgroundColor: "transparent" }}
                  disabled={true}
                >
                  <i
                    className="fa fa-refresh spin-it"
                    style={{ color: "darkGray" }}
                  />
                </button>
              )}
              {!isEmpty(album.cdURL) && cdURLenabled && (
                <a className="order-btn-cd" href={album.cdURL}>
                  <img className="cdLogo" alt="" src={cdLogo} />
                  {"CD/MERCH"}
                </a>
              )}
            </div>
            <Postage selected_printer={selected_printer} />

            <div className="order_returns">
              <p>
                <strong>Returns:</strong>
              </p>
              {!isEmpty(selected_printer) && (
                <div>
                  {selected_printer["seller_prices"]["ed_prices"][
                    "return_policy"
                  ].can_return == "1" && (
                    <div>
                      Seller accept returns in{" "}
                      <strong>
                        {" "}
                        {
                          selected_printer["seller_prices"]["ed_prices"][
                            "return_policy"
                          ].can_return_in_days
                        }
                      </strong>{" "}
                      days and{" "}
                      <strong>
                        {" "}
                        {
                          selected_printer["seller_prices"]["ed_prices"][
                            "return_policy"
                          ].return_paid_by
                        }
                      </strong>{" "}
                      pays return postage
                    </div>
                  )}
                  {selected_printer["seller_prices"]["ed_prices"][
                    "return_policy"
                  ].can_return == "0" && <p>Returns not accepted</p>}
                </div>
              )}
              {isEmpty(selected_printer) && <p>-</p>}
            </div>
            <div className="order_payments">
              <p>
                <strong>Payments:</strong>
              </p>
              <p>
                <i className="fa fa-cc-paypal" />
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  //ED_STYLE
  function onChangeEDStyle(style_index) {
    // console.log("style_index", style_index);
    setSelectedStyle(style_index);
  }

  //SLIDER
  function getSliderItems() {
    return [
      { edstyle: 0, album: album, shop_qr: props.user.shop_qr },
      { edstyle: 1, album: album, shop_qr: props.user.shop_qr },
    ];
  }

  //YEAR
  function setYear(releaseDate) {
    if (isEmpty(releaseDate)) {
      return "";
    }
    let year = new Date(releaseDate).getFullYear();
    return year;
  }

  //BUILD: genreOptionsSelect
  function selectableQuantityAndPrices() {
    //No_Print_selected=>default options
    if (isEmpty(selected_printer)) {
      return (
        <thead>
          <tr>
            <td style={{ width: "100%", fontWeight: "normal" }}>
              Select a Quantity
            </td>
          </tr>
        </thead>
      );
    }
    //No price to choose from priting studio.
    if (
      isEmpty(selected_printer["seller_prices"]["ed_prices"]["item_prices"])
    ) {
      return (
        <thead>
          <tr>
            <td style={{ width: "100%", fontWeight: "normal" }}>
              No prices given
            </td>
          </tr>
        </thead>
      );
    }
    //
    var tableData = [];

    selected_printer["seller_prices"]["ed_prices"]["item_prices"].forEach(
      (price, index) => {
        tableData.push(
          <tr
            key={index}
            style={{
              backgroundColor:
                selected_quantity === price.quantity
                  ? "lightGray"
                  : "transparent",
            }}
          >
            <td style={{ width: "20px" }}>
              <input
                style={{ paddingLeft: "8px" }}
                type="checkbox"
                checked={selected_quantity === price.quantity}
                onChange={() => selectPrice(price)}
              />
            </td>
            <td style={{ paddingTop: "8px" }}>
              {localeString(price.quantity)}
            </td>
            <td style={{ paddingTop: "8px" }}>{`${gbpCurrency(
              price.unit_price
            )}/unit+VAT`}</td>
            <td style={{ paddingTop: "8px" }}>
              <p>{gbpCurrency(price.unit_price * price.quantity)}+VAT</p>
            </td>
          </tr>
        );
      }
    );

    return <tbody>{tableData}</tbody>;
  }

  //LOCALE
  function localeString(value) {
    var formatter = new Intl.NumberFormat("en-IN", {
      maximumSignificantDigits: 10,
    });
    return formatter.format(value);
  }

  //UPDATE::Window - Update ContentWidth
  function updateWindowDimensions() {
    const _containerWidth = window.innerWidth - windowMargin;
    const _width = Math.min(300, _containerWidth);
    setContainerWidth(_width - 12);
    // console.log("_containerWidth,", _containerWidth, "_width", _width);
  }

  //CALCULATE_TOTAL_PRICE
  function calculateUnitPriceTotal(_selected_quantity) {
    //NO_PRICES
    if (
      isEmpty(selected_printer) ||
      isEmpty(selected_printer["seller_prices"]["ed_prices"]["item_prices"])
    ) {
      return;
    }
    //->unit_price
    let selected_item_quantity_price = selected_printer["seller_prices"][
      "ed_prices"
    ]["item_prices"].find((price) => price.quantity === _selected_quantity);
    if (isEmpty(selected_item_quantity_price)) {
      return;
    }
    //TOTAL_PRICE
    setTotalPrice(
      selected_item_quantity_price.unit_price *
        selected_item_quantity_price.quantity
    );
  }
}

export default EDItem;
