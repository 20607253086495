import React, { useEffect, useState } from "react";
import passwordValidator from "password-validator";
import posed from "react-pose";
import isEmpty from "../../../components/isEmpty";
import { resetPass } from "../../../utils/apis/unauth";
//JS
import Footer from "../../../components/Footer/Footer";
//css
import "./ResetPass.css";

//assets
import logoIcon from "../../../assets/img/songdrop-logo-black.png";
import resetIcon from "../../../assets/img/resetpass.png";
import about from "../../../assets/about/about.json";
//fa icons
const successFaIcon = "fa fa-check-circle";
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const infoFaIcon = "fa fa-info-circle";
const errorFaIcon = "fa fa-exclamation-circle";
const passInfo =
  "Password should have: 8-16 chars, 1 digit, 1 lowercase, 1 uppercase, 1 special char";

var schema = new passwordValidator();

const ShakePose = posed.div({
  shake: {
    applyAtEnd: { x: 0 },
    applyAtStart: { x: -10 },
    x: 0,
    transition: {
      type: "spring",
      stiffness: 1000,
      damping: 10,
      duration: 4,
    },
  },
});

//ResetPass class
function ResetPass() {
  //icons
  const [apiIcon, setApiIcon] = useState("");
  const [apiText, setApiText] = useState(passInfo);
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //get from urlpath
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  //userinput
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "Reset password | SongDrop.band";
    //password schema setup
    setupPasswordSchema();
    //
    retrieveDataFromUrl();
  }, []);

  //Get data from urlpath like email+code
  function retrieveDataFromUrl() {
    //get path
    var _email = window.location.href.split("/")[3].split("?")[1].split("=")[1];
    var _code = window.location.href.split("/")[3].split("?")[2].split("=")[1];
    //sets the email
    setEmail(_email);
    setCode(_code);
  }

  //Submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    //set api to working
    setApiWorking(true);
    setApiError("");
    setApiIcon(null);
    setApiText("Connecting...");

    //Validate password
    if (!schema.validate(password)) {
      const error = passInfo;
      setApiError(error);
      setApiWorking(false);
      setApiIcon(errorFaIcon);
      setApiText(error);
      return;
    }
    //Confirm matching passwords
    if (password !== confirmPassword) {
      const error = "Passwords are not matching";
      setApiError(error);
      setApiWorking(false);
      setApiIcon(errorFaIcon);
      setApiText(error);
      return;
    }
    //api
    try {
      //✅Success: User Password resetted
      await resetPass(email, code, password);
      //redirectAfterSuccess
      redirectAfterSuccess();
    } catch (error) {
      //❌Error: Validation falied
      setApiError(error.message);
      setApiWorking(false);
      setApiIcon(errorFaIcon);
      setApiText(error);
    }
  };

  //API.SUCCESS
  function redirectAfterSuccess() {
    setTimeout(function () {
      setApiIcon(successFaIcon);
      setApiText("Your password has been changed");
    }, 2000);
    setTimeout(function () {
      setApiIcon(apiFaIcon);
      setApiText("Redirecting...");
    }, 3500);
    setTimeout(function () {
      window.location.href = `/login`;
      setApiIcon(successFaIcon);
      setApiText("Your password has been changed");
    }, 4000);
  }

  //UPDATE: password value as state
  function handlePasswordChanged(password) {
    setPassword(password);
  }

  //UPDATE: confirm password value as state
  function handleConfirmPasswordChanged(password) {
    setConfirmPassword(password);
  }

  //VIEW
  return (
    <div className="reset-container">
      <div className="body">
        <div className="top">
          <img className="logo" alt="" src={logoIcon} onClick={goToLoginPage} />
          <div className="info">
            <i
              title="What is SongDrop?"
              className="fa fa-question-circle"
              aria-hidden="true"
              onClick={() => infoClicked()}
            />
          </div>
        </div>

        <div className="header">
          <div className="welcome">
            <p>Reset your password:</p>
          </div>
        </div>

        <div className="content">
          <div className="icon-container">
            <img className="logo" alt="" src={resetIcon} />
          </div>
          {isEmpty(apiError) && (
            <div className="info-block">
              <i className={apiIcon} aria-hidden="true"></i>
              <div>Enter a new password for your account</div>
              <p>{apiText}</p>
            </div>
          )}
          {!isEmpty(apiError) && (
            <ShakePose pose={["shake"]} poseKey={apiError}>
              <div className="info-block info-error">
                <i className={errorFaIcon} aria-hidden="true"></i>
                <p>{apiError}</p>
              </div>
            </ShakePose>
          )}
          {isApiWorking !== true && (
            <form className="input-container" onSubmit={handleSubmit}>
              <div className="input-field">
                <p>*New Password</p>
                <input
                  value={password}
                  placeholder="•••••••"
                  type="password"
                  onChange={(e) => handlePasswordChanged(e.target.value)}
                />
              </div>
              <div className="input-field">
                <p>*Confirm New Password</p>
                <input
                  value={confirmPassword}
                  placeholder="•••••••"
                  type="password"
                  onChange={(e) => handleConfirmPasswordChanged(e.target.value)}
                />
              </div>
              <div className="confirm-btn">
                {isApiWorking === false && (
                  <button
                    type="submit"
                    className="reset-btn"
                    disabled={!isSubmitEnabled()}
                  >
                    Change Password
                  </button>
                )}
                {isApiWorking === true && (
                  <button
                    className="btn btn_sign_in btn-primary"
                    style={{
                      backgroundColor: "transparent",
                      color: "darkgray",
                    }}
                    disabled={true}
                  >
                    <i
                      className="fa fa-refresh spin-it"
                      style={{ color: "darkGray" }}
                    />
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );

  //show info(what songdrop is about)
  function infoClicked() {
    window.open(about.pr_url, "_blank");
  }

  //Go to login page
  function goToLoginPage() {
    window.location.href = "/login";
  }

  //password schema setup
  function setupPasswordSchema() {
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(16) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .not()
      .spaces() // Should not have spaces
      .has()
      .symbols() // Should has symbols
      .is()
      .not()
      .oneOf(["Passw0rd", "Password123"]); // Blacklist these values
  }

  //Is Submission Enabled
  function isSubmitEnabled() {
    if (isApiWorking === true) {
      return false;
    } else {
      return password.length > 0 && confirmPassword.length > 0;
    }
  }
}

export default ResetPass;
