import React, { useEffect, useRef, useState } from "react";
//CSS
import "./EditAccount.css";

//JS
import Profile from "../0Profile/Profile";
import PayPal from "../1PayPalBusiness/PayPalBusiness";
import StoreId from "../1StoreId/StoreId";
import Prices from "../2Prices/PriceSetter";
import Addresses from "../3Addresses/Addresses";
import ChangePass from "../4Password/ChangePass";
import DeleteAccount from "../5Delete/DeleteAccount";
import isEmpty from "../../../../components/isEmpty";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//✅logged in
//https://edshop.songdrop.uk/account
//Account
function EditAccount(props) {
  //API
  const [profilebox_visible, setProfileBoxVisible] = useState(false);
  const [paypalbox_visible, setpayPalBoxVisible] = useState(false);
  const [storeid_visible, setStoreIdVisible] = useState(false);
  const [price_visible, setPriceBoxVisible] = useState(true);
  const [addressbox_visible, setAddressBoxVisible] = useState(false);
  const [passwordbox_visible, setPasswordBoxVisible] = useState(false);
  const [accountbox_visible, setAccountBoxVisible] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //SHOW_EDITING_IF_NO_VALUES!
  }, []);

  //VIEW
  return (
    <div className="edit-account">
      {/*0,PROFILE_ATTRIBUTES */}
      <div className="edit_box">
        <strong>Profile</strong>
        <button
          className="edit-btn"
          onClick={() => setProfileBoxVisible(!profilebox_visible)}
        >
          {profilebox_visible && <i className="fa fa-pencil" />}
          Edit
        </button>
        {profilebox_visible && <Profile user={props.user} />}
      </div>
      {/* 1,PAYPAL_ATTRIBUTES */}
      <div className="edit_box">
        {!isEmpty(props.user.paypal.paypal_error) && (
          <div className="acc_err">
            <i className="fa fa-exclamation-circle" />
            {props.user.paypal.paypal_error}
          </div>
        )}
        <strong>
          <i className="fa fa-paypal" /> PayPal Business{" "}
        </strong>
        <button
          className="edit-btn"
          onClick={() => setpayPalBoxVisible(!paypalbox_visible)}
        >
          {paypalbox_visible && <i className="fa fa-pencil" />}
          Edit
        </button>
        {paypalbox_visible && <PayPal user={props.user} />}
      </div>
      {/* 1,STORE_ID_ATTRIBUTES */}
      <div className="edit_box">
        {!isEmpty(props.user.paypal.paypal_error) && (
          <div className="acc_err">
            <i className="fa fa-exclamation-circle" />
            {
              "Request a 'store_id' to receive 5% on Digital Sales of EDs with your Store Id."
            }
          </div>
        )}
        <strong>
          <i className="fa fa-credit-card-alt" /> Store Id{" "}
        </strong>
        <button
          className="edit-btn"
          onClick={() => setStoreIdVisible(!storeid_visible)}
        >
          {storeid_visible && <i className="fa fa-pencil" />}
          Edit
        </button>
        {storeid_visible && <StoreId user={props.user} />}
      </div>
      {/* 2,PRICES_ATTRIBUTES */}
      <div className="edit_box">
        {!isEmpty(props.user.seller_prices.prices_error) && (
          <div className="acc_err">
            <i className="fa fa-info-circle" />
            {props.user.seller_prices.prices_error}
          </div>
        )}
        <strong>
          <i className="fa fa-money" /> Printing Prices
        </strong>
        <button
          className="edit-btn"
          onClick={() => setPriceBoxVisible(!price_visible)}
        >
          {price_visible && <i className="fa fa-pencil" />}
          Edit
        </button>
        {price_visible && <Prices user={props.user} />}
      </div>
      {/* 3,ADDRESSES */}
      <div className="edit_box">
        <strong>Address</strong>
        <button
          className="edit-btn"
          onClick={() => setAddressBoxVisible(!addressbox_visible)}
        >
          {addressbox_visible && <i className="fa fa-pencil" />}
          Edit
        </button>
        {addressbox_visible && <Addresses user={props.user} />}
      </div>
      {/* 4,USER_PASSWORD */}
      <div className="user_password">
        <strong>Password</strong>
        <button
          className="edit-btn"
          onClick={() => setPasswordBoxVisible(!passwordbox_visible)}
        >
          {passwordbox_visible && <i className="fa fa-pencil" />}
          Edit
        </button>
        {passwordbox_visible && (
          <div>
            <ChangePass user={props.user} />
            <br />
          </div>
        )}
      </div>
      {/* 5,DELETE_ACCOUNT */}
      <div className="edit_box">
        <strong>Account</strong>
        <button
          className="edit-btn"
          onClick={() => setAccountBoxVisible(!accountbox_visible)}
        >
          {accountbox_visible && <i className="fa fa-pencil" />}
          Edit
        </button>
        {accountbox_visible && <DeleteAccount user={props.user} />}
      </div>
    </div>
  );
}

export default EditAccount;
