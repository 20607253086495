import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import QRCode from "react-qr-code";

//CSS
import "./Home.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
import about from "../../../assets/about/about.json";

//LINK
//✅logged in
//https://edshop.songdrop.uk/
//Dashboard-HOME
function Home(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);

  //data
  const [results_title, setResultsTitle] = useState("");
  const [results_data, setResultsData] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //api
    getHome();
  }, []);

  //API: call data
  async function getHome(user_id) {
    // console.log("data");
    //set api to working
    setApiWorking(true);

    setTimeout(function () {
      setApiWorking(false);
      //last orders
      setResultsTitle("You ordered last");
      //eds
      setResultsData([]);
    }, 100);
  }

  //VIEW
  return (
    <div className="home-body">
      {/* API_LOADING_CONTENT */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && (
        <div className="home-content">
          <div className="buy-me">
            <div>Like the service? </div>
            <div className="buy-btn">
              <a href="https://www.buymeacoffee.com/gabzlabs">
                <img
                  alt=""
                  src="https://img.buymeacoffee.com/button-api/?text=&emoji=&slug=gabzlabs&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff"
                />
              </a>
            </div>
          </div>
          {/* ✅API_CONTENT_FOUND */}
          {/* {!isEmpty(results_data) && ( */}
          <div className="home_data">
            <div className="title">
              <strong>{results_title}</strong>
            </div>
            <div className="grid">{ResultTableUI()}</div>
          </div>
          {/* )} */}
        </div>
      )}
    </div>
  );

  //BUILD_TABLE_DATA
  function ResultTableUI() {
    var tableData = [];
    if (isEmpty(results_data)) {
      return;
    }
    results_data.forEach((data, index) => {
      tableData.push(
        <div
          key={index}
          className="img_data"
          onClick={() => orderBtnPressed(data.album_id)}
        >
          <div className="flip">
            <div className="flip-inner">
              <div className="flip-front">
                <img alt="" src={data.cover_img_url} />
              </div>
              <div className="flip-back">
                <div className="qrbox">
                  <div className="qrcode">
                    <QRCode
                      id="QRCode"
                      value={generateQrCode(data.album_id)}
                      size={88}
                    />
                  </div>
                  <p className="scanme">SCAN ME</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return tableData;
  }

  //CREATE: QR url value
  function generateQrCode(album_id) {
    //
    if (!isEmpty(album_id)) {
      return `${about.qr_url}/${album_id}`;
    } else {
      return "";
    }
  }

  //Go-to: ORDER_ED
  function orderBtnPressed(album_id) {
    const urlPath = `/ordered/${album_id}`;
    window.location.href = urlPath;
  }
}

export default Home;
