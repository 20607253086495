import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import {
  getCurrency,
  currencyToSymbol,
} from "../../../../components/currencyFormatter";
import { updateEDPrices } from "../../../../utils/apis/api/auth_api_order";
//json
//CSS
import "./PriceSetter.css";

//json
import currencies from "../../../../assets/json/currencies.json";
import about from "../../../../assets/about/about.json";
import PostageSetter from "./PostageSetter";
import ReturnSetter from "./ReturnSetter";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const apiFaSuccess = "fa fa-check-circle";

//EDPriceBox
function EDPriceBox(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [isApiSuccess, setApiSuccess] = useState(false);
  //ViewDidLoad
  useEffect(() => {}, []);

  //global_pricing_currency+vat
  const [currency, setCurrency] = useState("GBP");
  const [vat, setVAT] = useState(20);
  const [isLegalAccepted, setLegalAccepted] = useState(false);

  //VAT_select:0-100%
  const [vat_array, setVATArray] = useState(vatSelectOptionsZeroToHundread());

  const [price_array, setPriceArray] = useState([]);
  const [postage_fee, setPostageFee] = useState(0);
  const [delivery_policy_url, setDeliveryPolicyURL] = useState("");
  const [return_policy, setReturnsPolicy] = useState({
    can_return: "0",
    can_return_in_days: "30",
    return_paid_by: "seller",
  });

  //ViewDidLoad
  useEffect(() => {
    //set prices
    setPriceArray(props.user.seller_prices["ed_prices"]["item_prices"]);
    setPostageFee(props.user.seller_prices["ed_prices"]["postage_fee"]);
    setDeliveryPolicyURL(
      props.user.seller_prices["ed_prices"]["delivery_policy_url"]
    );
    setReturnsPolicy(props.user.seller_prices["ed_prices"]["return_policy"]);
  }, []);

  //API_EDIT_DATA
  const updateData = async (event) => {
    //PRICE_ERROR
    let priceError = checkForPriceErrors();
    if (!isEmpty(priceError)) {
      alert(priceError);
      return;
    }
    setApiSuccess(false);
    setApiWorking(true);

    try {
      //bodyParams
      var bodyParams = [];
      bodyParams.push(`postage_fee=${postage_fee}`);
      bodyParams.push(`delivery_policy_url=${delivery_policy_url}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`ed_prices=${JSON.stringify(price_array)}`);
      bodyParams.push(`return_policy=${JSON.stringify(return_policy)}`);
      const bParameters = bodyParams.join("&");
      //get user details
      const result = await updateEDPrices(bParameters);
      // console.log(result);
      //✅SUCCESS
      setApiSuccess(true);
      // console.log("SELLER_PRICES_UPDATED");
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      console.log("EDS_GET_ERROR", error, error.message);
      alert(error.message);
      setApiWorking(false);
    }
  };

  function updateReturnPolicy(key, value) {
    let new_return_policy = _.cloneDeep(return_policy);
    new_return_policy[key] = value;
    setReturnsPolicy(new_return_policy);
    setApiSuccess(false);
  }

  //UPDATE data
  function updateChanges() {
    let rows = _.cloneDeep(price_array);
    setPriceArray(rows);
    setApiSuccess(false);
  }

  //INSERT: NEW_ITEM_ROW
  function insertNewItem() {
    let new_item = {
      quantity: "",
      unit_price: "",
      vat: vat,
      currency: currency,
    };
    //add new row
    var items = price_array;
    items.push(new_item);
    //props
    updateChanges("items", items);
  }

  //CHANGE: CURRENCY
  function currencyDidChange(currency) {
    setCurrency(currency);
    setApiSuccess(false);
  }

  //CHANGE: VAT
  function vatDidChange(new_vat) {
    let new_price_array = price_array.map((price, index) => {
      price_array[index]["vat"] = new_vat;
    });
    setVAT(new_vat);
    setPriceArray(new_price_array);
    //
    updateChanges("vatDidChange", new_vat);
  }

  //CHECK_BOX: LEGAL_REQ_ACCEPTED
  function didCheckLegal() {
    setLegalAccepted(!isLegalAccepted);
    setApiSuccess(false);
  }

  //CHANGE:QUANTITY_AT_INDEX
  function changeQuantityAtIndex(quantity, index) {
    // console.log("changeQuantityAtIndex", quantity, index);
    const _quantity = Number(quantity.replace(/\D/g, ""));
    price_array[index].quantity = _quantity;
    updateChanges();
  }

  //CHANGE:PRICE_AT_INDEX
  function changePriceAtIndex(price, index) {
    const _price = price; //|| 0;
    // console.log("changePriceAtIndex", _price, index);
    price_array[index].unit_price = _price;
    updateChanges();
  }

  //REMOVE: ITEM_ROW_AT_INDEX
  function removeItemAtIndex(index) {
    let items = price_array.filter(function (item, _index) {
      return _index !== index;
    });
    setPriceArray(items);
    //props
    // updateChanges();
    setApiSuccess(false);
  }

  //update
  function updatePostage(key, value) {
    switch (key) {
      case "postage_fee":
        setPostageFee(value);
        setApiSuccess(false);
        break;
      case "delivery_policy_url":
        setDeliveryPolicyURL(value);
        setApiSuccess(false);
        break;
      default:
        break;
    }
  }
  //VIEW
  return (
    <div className="price-setter-body">
      <div className="items_table">
        <p>
          <strong>DELIVERING ED Prints</strong>
          {"  "}
          <a href={about.edguideline_url} target="_blank">
            <i className="fa fa-info-circle" />
            What is an ED?
          </a>
        </p>
        <p>
          Double sided, Rounded Square(93mm x 93mm) Beermat Print - Bright,
          White Paperboard(1.4mm thick)
        </p>
        <br />
        <table>
          <thead>
            <tr>
              <td>
                <p>Quantity</p>
              </td>
              <td>
                <p>
                  <select
                    disabled={true}
                    value={`${currency}`}
                    onChange={(e) => currencyDidChange(e.target.value)}
                  >
                    {currencies.map((item, index) => {
                      return (
                        <option key={index} value={item.cc}>
                          {`${item.cc}`}
                        </option>
                      );
                    })}
                  </select>{" "}
                  Unit Price
                </p>
              </td>
              <td>
                <p>Total</p>
              </td>
              <td>
                <select
                  value={`${vat}`}
                  onChange={(e) => vatDidChange(e.target.value)}
                >
                  {vat_array.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {`${item.key}`}
                      </option>
                    );
                  })}
                </select>
              </td>
              <td>
                <p>Total + VAT</p>
              </td>
            </tr>
          </thead>
          <tbody>{displayTableRows()}</tbody>
        </table>
        {/* ADD_NEW_PRICE_ROW */}
        <div id="new-item" className="add_new_row">
          <button onClick={() => insertNewItem()}>+New item</button>
        </div>
        <br />
        {/* POSTAGE_SETTER */}
        <div className="_postage">
          <PostageSetter
            postage_fee={postage_fee}
            delivery_policy_url={delivery_policy_url}
            updatePostage={updatePostage}
          />
          <ReturnSetter
            return_policy={return_policy}
            updateReturnPolicy={updateReturnPolicy}
          />
        </div>
        {/* LEGAL_BOX_TO_ACCEPT_BEFORE_REGISTER */}
        <div className="checkbox">
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              checked={isLegalAccepted}
              onChange={() => didCheckLegal()}
            />
          </div>
          <p style={{ fontWeight: isLegalAccepted ? "bold" : "normal" }}>
            I am able to deliver EDs in the specific criteria.
          </p>
        </div>
        <div className="save">
          <br />
          {/* API_WORKING */}
          {isApiWorking && (
            <div className="stat-load">
              <div className="stat-loader">
                <i className={apiFaIcon} />
              </div>
            </div>
          )}
          {/* API_NOT_WORKING */}
          {!isApiWorking && (
            <button
              className="update-acc-btn"
              onClick={(e) => updateData(e)}
              disabled={!isLegalAccepted}
            >
              {!isApiSuccess && <p>Save</p>}
              {isApiSuccess && <i className={apiFaSuccess} />}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  //DISPLAY_TABLE_DATA
  function displayTableRows() {
    var tableData = [];
    //
    if (isEmpty(price_array)) {
      return tableData;
    }
    //display tablerows
    price_array.forEach((itemRow, index) => {
      let tableRow = (
        <tr key={index}>
          <td className="input-td">
            <input
              type="number"
              min="1"
              step="1"
              className="tq-input"
              placeholder="1"
              value={itemRow.quantity}
              onChange={(e) => changeQuantityAtIndex(e.target.value, index)}
            />
          </td>
          <td className="input-td">
            <div className="flex_input">
              <p className="prefix">{currencyToSymbol(currency)}</p>
              <input
                type="number"
                min="0.0"
                step="0.01"
                value={itemRow.unit_price}
                placeholder={`0.00`}
                onChange={(e) => changePriceAtIndex(e.target.value, index)}
              />
            </div>
          </td>
          <td className="amount-td">
            <p>
              {getCurrency(
                calculateNoVATAmount(itemRow.quantity, itemRow.unit_price),
                currency
              )}
            </p>
          </td>
          <td className="amount-td">
            <p>
              {getCurrency(
                calculateVATAmount(
                  itemRow.quantity,
                  itemRow.unit_price,
                  itemRow.vat
                ),
                currency
              )}
            </p>
          </td>
          <td className="amount-td">
            <button onClick={() => deleteRowAtIndex(index)}>
              {getCurrency(
                calculateNetAmount(
                  itemRow.quantity,
                  itemRow.unit_price,
                  itemRow.vat
                ),
                currency
              )}

              <i id="close-item" className="fa fa-close" />
            </button>
          </td>
        </tr>
      );
      tableData.push(tableRow);
    });
    //
    return tableData;
  }

  //CALCULATE: Total NO VAT: quantity * unit_price
  function calculateNoVATAmount(quantity, unit_price) {
    if (isEmpty(quantity) || isEmpty(unit_price)) {
      return 0;
    }
    {
      return quantity * unit_price;
    }
  }

  //CALCULATE: Total_VAT_ONLY: (quantity * unit_price * vat) / 100
  function calculateVATAmount(quantity, unit_price, vat) {
    if (isEmpty(quantity) || isEmpty(unit_price)) {
      return 0;
    }
    {
      return (quantity * unit_price * vat) / 100;
    }
  }

  //CALCULATE: TOTAL_NET_INC_VAT: (quantity * unit_price + (quantity * unit_price * vat) / 100)
  function calculateNetAmount(quantity, unit_price, vat) {
    if (isEmpty(quantity) || isEmpty(unit_price)) {
      return 0;
    }
    {
      return quantity * unit_price + (quantity * unit_price * vat) / 100;
    }
  }

  //SELECT_VAT_0-100
  function vatSelectOptionsZeroToHundread() {
    return [...Array(100).keys()].map((i) => ({
      value: i,
      key: `VAT(${i}%)`,
    }));
  }

  //BUTTON: CONFIRM_DELETE_ROW
  function deleteRowAtIndex(index) {
    if (window.confirm("Would you like to delete the selected row?")) {
      removeItemAtIndex(index);
    }
  }

  function checkForPriceErrors() {
    //check album props are correct
    var error_msg = "";
    //check if album has tracks(not empty)
    // if (isEmpty(prices)) {
    //   error_msg = "You need to add at least 1 price";
    // }
    //check track has empty url or data
    price_array.map((price, index) => {
      if (isEmpty(price.quantity)) {
        error_msg = `The Price at index ${index} doesn't have a quantity setup`;
      }
      if (isEmpty(price.unit_price)) {
        error_msg = `The Price at index ${index} doesn't have a unit price setup`;
      }
      if (isEmpty(price.vat)) {
        error_msg = `The Price at index ${index} doesn't have a VAT setup`;
      }
    });
    return error_msg;
  }
}

export default EDPriceBox;
