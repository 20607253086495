import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import CountrySelect from "../../../../components/CountrySelect";
//json
//CSS
import "./Addresses.css";

var new_address = {
  id: "new_address",
  street: "",
  city: "",
  postcode: "",
  country: "",
  is_primary: "0",
};

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//Addresses
function Addresses(props) {
  // //API
  const [isApiWorking, setApiWorking] = useState(false);
  //
  const [primary_address_id, setPrimaryAddressId] = useState("");
  const [user_addresses, setUserAddresses] = useState([]);
  //editable_address
  const [address_to_edit, setAddressToEdit] = useState(null);
  const [street, setStreetAttr] = useState("");
  const [city, setCityAttr] = useState("");
  const [postcode, setPostcodeAttr] = useState("");
  const [country, setCountryAttr] = useState("");
  const [is_primary, setIsPrimaryAttr] = useState("0");

  //ViewDidLoad
  useEffect(() => {
    //
    setUserAddresses(props.user.addresses ?? []);
    setPrimaryAddressId(getPrimaryId(props.user.addresses));
  }, []);

  //API_EDIT_DATA
  const updateData = async (event) => {
    event.preventDefault();
    setApiWorking(true);
    //API
    var queryParams = [];

    const qParameters = queryParams.join("&");
    setTimeout(function () {
      console.log("ACCOUNT_UPDATED");
      setApiWorking(false);
      // window.location.href = "/account?edit=1";
    }, 2000);
  };

  //DELETE_API
  const deleteAddress = async () => {
    //CANNOT_DELETE_PRIMARY_ADDRESS
    if (address_to_edit.is_primary == "1") {
      alert(
        "You cannot delete your primary address. Please change your primary address, before deleting this address."
      );
      return;
    }
    setApiWorking(true);
    //
    var queryParams = [];
    const qParameters = queryParams.join("&");
    setTimeout(function () {
      console.log("ADDRESS_DELETED");
      setApiWorking(false);
      // window.location.href = "/account?edit=1";
    }, 2000);
  };

  //UPDATE
  //API_EDIT_DATA
  const updateAddressToEdit = async (event, value, key) => {
    if (!isEmpty(event)) {
      event.preventDefault();
    }
    switch (key) {
      case "street":
        setStreetAttr(value);
        break;
      case "city":
        setCityAttr(value);
        break;
      case "postcode":
        setPostcodeAttr(value);
        break;
      case "country":
        setCountryAttr(value);
        break;
      case "is_primary":
        setPrimaryAddressId(address_to_edit.id);
        setIsPrimaryAttr(value);
        break;
    }
  };

  //UPDATE
  function didSelectCountry(country) {
    let _country = country.name;
    updateAddressToEdit(null, _country, "country");
  }

  //VIEW
  return (
    <div className="address_box">
      {/* USER_ADDRESSES! */}
      {displayAddressRows()}
      {/* CREATE_NEW_ADDRESS */}
      {!isEmpty(address_to_edit) && address_to_edit.id === "new_address" && (
        <div>
          <br />
          {editAddressTable()}
        </div>
      )}
      <div className="save">
        <br />
        {/* API_WORKING */}
        {isApiWorking && (
          <div className="stat-load">
            <div className="stat-loader">
              <i className={apiFaIcon} />
            </div>
          </div>
        )}
        {/* API_NOT_WORKING */}
        {!isApiWorking && (
          <button className="update-acc-btn" onClick={(e) => updateData(e)}>
            Save
          </button>
        )}
      </div>
    </div>
  );

  //DISPLAY_TABLE_DATA
  function displayAddressRows() {
    var tableData = [];
    //
    if (isEmpty(props.user.addresses)) {
      return tableData;
    }

    //display tablerows
    props.user.addresses.forEach((_address, index) => {
      console.log(_address);
      let tableRow = (
        <div className="address-row" key={index}>
          <div className="is_primary_address">
            <input
              type="radio"
              value={true}
              checked={_address.id === primary_address_id}
            />
            <p
              style={{
                fontWeight: _address.is_primary === "1" ? "bold" : "normal",
              }}
            >
              {" "}
              Primary Address
            </p>
          </div>
          <div>{returnLongAddress(_address)}</div>
          <div>
            <button onClick={() => selectAddressToEdit(index)}>Edit</button>
            <button
              className="btn-link-style"
              onClick={() => createNewAddressToEdit()}
            >
              +Add new address
            </button>
          </div>
          {!isEmpty(address_to_edit) && address_to_edit.id === _address.id && (
            <div>
              <br />
              {editAddressTable()}
            </div>
          )}
        </div>
      );
      tableData.push(tableRow);
    });

    return tableData;
  }

  //EDIT_
  function editAddressTable() {
    return (
      <div className="new_address">
        <div>
          <button
            className="btn-link-style"
            onClick={() => setAddressToEdit(null)}
          >
            Edit Address
            <i id="close-item" className="fa fa-close" />
          </button>
        </div>
        <div>
          <p>Street Address</p>
          <input
            className="address_input"
            value={street}
            onChange={(e) => updateAddressToEdit(e, e.target.value, "street")}
            placeholder="Street Address"
          />
          <p>City</p>
          <input
            className="address_input"
            value={city}
            onChange={(e) => updateAddressToEdit(e, e.target.value, "city")}
            placeholder="City"
          />
          <p>Postcode</p>
          <input
            className="address_input"
            value={postcode}
            onChange={(e) => updateAddressToEdit(e, e.target.value, "postcode")}
            placeholder="Postcode"
          />
          <p>Country</p>
          <div className="country_wrap">
            <CountrySelect
              didSelectCountry={didSelectCountry}
              selected_country={country}
            />
          </div>
          <div className="is_primary_address">
            <input
              type="radio"
              value={true}
              checked={address_to_edit.id === primary_address_id}
              onChange={(e) => updateAddressToEdit(null, "1", "is_primary")}
            />
            <p
              style={{
                fontWeight: is_primary === "1" ? "bold" : "normal",
              }}
            >
              {" "}
              Select Primary Address
            </p>
          </div>
          {address_to_edit.id !== "new_address" && (
            <div>
              <br />
              {/* API_WORKING */}
              {isApiWorking && (
                <div className="stat-load">
                  <div className="stat-loader">
                    <i className={apiFaIcon} />
                  </div>
                </div>
              )}
              {/* API_NOT_WORKING */}
              {!isApiWorking && (
                <button onClick={deleteToAsk}>Delete Address</button>
              )}
              <br />
            </div>
          )}
        </div>
      </div>
    );
  }

  //MARK_ORDER_STATUS_AS_DELIVERED
  function deleteToAsk() {
    if (window.confirm("Would you like to delete this address?")) {
      deleteAddress();
    }
  }

  //CREATE_NEW_ADDRESS
  function createNewAddressToEdit() {
    addressToEdit(new_address);
  }

  //SELECT_ADDRESS_TO_EDIT
  function selectAddressToEdit(index) {
    let _addressToEdit = props.user.addresses[index];
    addressToEdit(_addressToEdit);
  }

  //ADDRESS_TO_EDIT
  function addressToEdit(address) {
    setAddressToEdit(address);
    setStreetAttr(address.street);
    setCityAttr(address.city);
    setPostcodeAttr(address.postcode);
    setCountryAttr(address.country);
    setIsPrimaryAttr(address.is_primary);
  }

  //PRIMARY_ADDRESS_ID
  function getPrimaryId(addresses) {
    var primary_id = "";
    addresses.forEach((_address, index) => {
      if (_address.is_primary == "1") {
        primary_id = _address.id;
      }
    });
    return primary_id;
  }

  //
  function returnLongAddress(address) {
    return (
      address["street"] +
      ", " +
      address["city"] +
      ", " +
      address["postcode"] +
      ", " +
      address["country"]
    );
  }
}

export default Addresses;
