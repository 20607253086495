import React, { useEffect, useState } from "react";
import emailvalidator from "email-validator";
import passwordValidator from "password-validator";
import CountrySelector from "../../../components/CountrySelect";
import isEmpty from "../../../components/isEmpty";
import { signUp } from "../../../utils/apis/unauth";

//JS
import Footer from "../../../components/Footer/Footer";
//css
import "./Register.css";
//icons+about
import logoIcon from "../../../assets/img/songdrop-logo-black.png";
import about from "../../../assets/about/about.json";

//password schema
//8-16 chars,1 digit,1 special,1 lowercased,1 uppercased
var schema = new passwordValidator();

//Helps building the registration form
const attributes = (key, value) => {
  return {
    Name: key,
    Value: value,
  };
};

//LINK
//👤 not logged in
//https://edshop.songdrop.uk/register
//Register
function Register() {
  //api
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //user input data
  const [account_type, setAccountType] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState(null);
  const [countryName, setCountryName] = useState("");
  //legal
  const [isLegalAccepted, setLegalAccepted] = useState(false);

  //

  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "Register | EDShop";

    //https://edshop.songdrop.uk/register?type={1}
    //-- {id}
    //FIXME: Add ?account=1
    //password schema setup
    setupPasswordSchema();
  }, []);

  //API call: Submit Login
  const handleSubmit = async (event) => {
    event.preventDefault();
    //set api to working
    setApiError("");
    setApiWorking(true);

    //check valid email address form
    if (!emailvalidator.validate(email)) {
      let error = "Email is not valid: example@mail.com";
      setApiError(error);
      setApiWorking(false);
      scrollToError();
      return;
    }
    //check if password valid
    if (!schema.validate(password)) {
      const error =
        "Password should have: 8-16 chars, 1 uppercase, 1 lowercase, 1 digit, 1 symbol.";
      setApiError(error);
      setApiWorking(false);
      scrollToError();
      return;
    }
    //call api
    let _email = email;
    let _pass = password;
    let usertype = String(account_type);
    const attributeList = [];
    attributeList.push(attributes("custom:name", name));
    attributeList.push(attributes("custom:username", username));
    attributeList.push(attributes("custom:usertype", usertype));
    attributeList.push(attributes("custom:street", street));
    attributeList.push(attributes("custom:city", city));
    attributeList.push(attributes("custom:postcode", postcode));
    attributeList.push(attributes("custom:country", countryName));

    //Register
    try {
      //✅Success: User Registeres
      await signUp(_email, _pass, attributeList);
      alert(
        `Sign Up Successed\nWe sent you an email to ${_email} with your verification code.`
      );
      //email was sent to verify user account
      window.location.href = `/validate=${_email}`;
    } catch (error) {
      //❌Error: Register error error
      alert(`Sign Up Failed\n${error.message}`);
      setApiError(`Sign Up Failed\n${error.message}`);
      setApiWorking(false);
      setTimeout(function () {
        scrollToError();
      }, 100);
    }
  };

  //INPUT FUNCTIONS
  //->SET:ACCOUNT_TYPE
  function handleAccTypeChanged(account_type) {
    setAccountType(account_type);
  }

  //->SET:EMAIL
  function handleEmailChanged(email) {
    setEmail(email);
  }

  //->SET:PASSWORD
  function handlePasswordChanged(password) {
    setPassword(password);
  }

  //->SET:NAME
  function handleNameChanged(name) {
    setName(name);
  }

  //->SET:USERNAME
  function handleUsernameChanged(username) {
    setUsername(username);
  }

  //->SET:STREET_ADDRESS
  function handleStreetAddressChange(street_address) {
    setStreet(street_address);
  }

  //->SET:CITY
  function handleCityChanged(city) {
    setCity(city);
  }

  //->SET:POSTCODE
  function handlePostcodeChanged(postcode) {
    setPostcode(postcode);
  }

  //Home country
  function didSelectCountry(country) {
    setCountry(country);
    setCountryName(country.name);
  }

  //LEGAL_BUTTON_TO_ACCEPT
  function didCheckLegal() {
    setLegalAccepted(!isLegalAccepted);
  }

  //VIEW
  return (
    <div className="register-container">
      <div className="body">
        <div className="top">
          <img className="logo" alt="" src={logoIcon} onClick={goToLoginPage} />
          <div className="info">
            <i
              title="What is SongDrop?"
              className="fa fa-question-circle"
              aria-hidden="true"
              onClick={() => infoClicked()}
            />
          </div>
        </div>

        <div className="content" id="scrollTo">
          {/*---->>>>>> START_FILLING_REGISTRATION_FORM */}
          <p className="join-text">Join to EDShop.SongDrop.uk</p>
          <p className="join-subtext">
            Create an EDShop account or <a onClick={goToLoginPage}>login</a>
          </p>
          {!isEmpty(apiError) && (
            <div className="error-container">
              <p className="title">Error during sign up:</p>
              <p className="error-message">{apiError}</p>
            </div>
          )}
          <div className="header">
            {/* SELECTED_ACCOUNT_TYPE_DISPLAY */}
            <div className="welcome">
              <strong>
                <p>Create EDShop account if you are:</p>
              </strong>
              <p
                style={{
                  backgroundColor: account_type == 0 ? "yellow" : "transparent",
                }}
              >
                <strong>•Artist</strong> and want to order your On-Sale{" "}
                <a href={about.edguidelinesurl}>
                  <strong>EDs</strong>
                </a>{" "}
                for your gig.
              </p>
              <p
                style={{
                  backgroundColor: account_type == 1 ? "yellow" : "transparent",
                }}
              >
                <strong>•Printing Studio</strong> to offer{" "}
                <a href={about.edguidelinesurl}>
                  <strong>ED</strong>
                </a>{" "}
                printing service for our clients.
              </p>
              <p
                style={{
                  backgroundColor: account_type == 2 ? "yellow" : "transparent",
                }}
              >
                <strong>Record Store</strong> to sell unique{" "}
                <a href={about.edguidelinesurl}>
                  <strong>EDs</strong>
                </a>{" "}
                and get paid for it.
              </p>
              <p
                style={{
                  backgroundColor: account_type == 3 ? "yellow" : "transparent",
                }}
              >
                <strong>•Local Shop</strong> to sell local artists'{" "}
                <a href={about.edguidelinesurl}>
                  <strong>EDs</strong>
                </a>{" "}
                for your customers.
              </p>
            </div>
          </div>
          {/* REGISTRATION_FORM_TO_FILL */}
          <form className="form" onSubmit={handleSubmit}>
            {/* SELECT_USER_ACCOUNT_TYPE */}
            <div className="input-field">
              <strong>
                <p>Register as:</p>
              </strong>
              <select
                value={account_type}
                onChange={(e) => handleAccTypeChanged(e.target.value)}
              >
                <option value={0}>Artist</option>
                <option value={1}>Printing Studio</option>
                <option value={2}>Record Store</option>
                <option value={3}>Local Shop</option>
              </select>
            </div>
            {/* SELECT_USER_EMAIL */}
            <div className="input-field">
              <p>Email</p>
              <input
                value={email}
                placeholder="example@mail.com"
                style={{ textTransform: "lowercase" }}
                onChange={(e) => handleEmailChanged(e.target.value)}
              />
            </div>
            {/* SELECT_USER_PASSWORD */}
            <div className="input-field">
              <p>Password</p>
              <input
                value={password}
                placeholder="•••••••"
                type="password"
                onChange={(e) => handlePasswordChanged(e.target.value)}
              />
            </div>
            {/* SELECT_USER_NAME */}
            <div className="input-field">
              <p>Name</p>
              <input
                value={name}
                placeholder="Name/Company name"
                onChange={(e) => handleNameChanged(e.target.value)}
              />
            </div>
            {/* SELECT_USER_USERNAME */}
            <div className="input-field">
              <p>Username</p>
              <input
                value={username}
                placeholder="Username"
                onChange={(e) => handleUsernameChanged(e.target.value)}
              />
            </div>
            {/* SELECT_USER_STREET_ADDRESS */}
            <div className="input-field">
              <p>Street Address</p>
              <input
                value={street}
                placeholder="Street Address"
                onChange={(e) => handleStreetAddressChange(e.target.value)}
              />
            </div>
            {/* SELECT_USER_CITY */}
            <div className="input-field">
              <p>City</p>
              <input
                value={city}
                placeholder="City"
                onChange={(e) => handleCityChanged(e.target.value)}
              />
            </div>
            {/* SELECT_USER_POSTCODE */}
            <div className="input-field">
              <p>Postcode</p>
              <input
                value={postcode}
                placeholder="Postcode"
                onChange={(e) => handlePostcodeChanged(e.target.value)}
              />
            </div>
            {/* SELECT_USER_COUNTRY */}
            <div className="input-field">
              <p>Country</p>
              <CountrySelector
                didSelectCountry={didSelectCountry}
                selected_country={isEmpty(country) ? "" : country.name}
              />
            </div>
            {/* LEGAL_BOX_TO_ACCEPT_BEFORE_REGISTER */}
            <div className="checkbox">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={isLegalAccepted}
                  onChange={() => didCheckLegal()}
                />
              </div>
              <p>
                By clicking Join, you agree to our{" "}
                {<a onClick={goToTermsUrl}>Terms of Usage</a>}, and our{" "}
                {<a onClick={goToPrivacyUrl}>Privacy Policy</a>}.
              </p>
            </div>
            {/* REGISTER_FORM_BUTTON */}
            <div className="button-container">
              {/* -->SUBMIT_BUTTON_ */}
              {isApiWorking === false && (
                <button
                  type="submit"
                  className="joinButton"
                  disabled={!isSubmitEnabled()}
                >
                  Join
                </button>
              )}
              {/* -->API_IS_WORKING_CURRENTLY */}
              {isApiWorking === true && (
                <button
                  className="btn btn_sign_in btn-primary"
                  style={{ backgroundColor: "transparent", color: "darkgray" }}
                  disabled={true}
                >
                  <i
                    className="fa fa-refresh spin-it"
                    style={{ color: "darkGray" }}
                  />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      {/* COMPANY_FOOTER */}
      <div className="footer-container">
        <div className="footer-wrapper">
          <Footer />
        </div>
      </div>
    </div>
  );

  //Scroll to error
  function scrollToError() {
    if (isEmpty(apiError)) {
      return;
    }
    var element = document.getElementById("scrollTo");
    element.scrollIntoView();
  }

  //temrs url
  function goToTermsUrl() {
    window.open(about.terms_url, "_blank");
  }

  //privacy url
  function goToPrivacyUrl() {
    window.open(about.privacy_url, "_blank");
  }

  //Sales url
  function goToSalesFaqUrl() {
    window.open(about.salesurl, "_blank");
  }

  //show info(what songdrop is about)
  function infoClicked() {
    window.open(about.pr_url, "_blank");
  }

  //Go to login page
  function goToLoginPage() {
    window.location.href = "/login";
  }

  //password schema setup
  function setupPasswordSchema() {
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(16) // Maximum length 16
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .not()
      .spaces() // Should not have spaces
      .has()
      .symbols() // Should has symbols
      .is()
      .not()
      .oneOf(["Passw0rd", "Password123"]); // Blacklist these values
  }

  //Enable/Disable submit button
  function isSubmitEnabled() {
    if (isApiWorking === true) {
      return false;
    } else {
      return (
        !isEmpty(email) &&
        !isEmpty(password) &&
        !isEmpty(username) &&
        !isEmpty(name) &&
        !isEmpty(street) &&
        !isEmpty(city) &&
        !isEmpty(postcode) &&
        !isEmpty(country) &&
        isLegalAccepted
      );
    }
  }
}

export default Register;
