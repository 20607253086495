import React from "react";
//JS
import EDStyleOne from "./EDStyleOne";
import EDStyleTwo from "./EDStyleTwo";

////EDDesign
function EDDesign(props) {
  //VIEW
  return (
    <div>
      {props.edStyle === 0 && (
        <EDStyleOne
          width={props.width}
          height={props.height}
          fontSize={props.fontSize}
          trackFontSize={props.trackFontSize}
          album={props.album}
          isBack={props.isBack}
          shop_qr={props.shop_qr ?? ""}
        />
      )}
      {props.edStyle === 1 && (
        <EDStyleTwo
          width={props.width}
          height={props.height}
          fontSize={props.fontSize}
          trackFontSize={props.trackFontSize}
          album={props.album}
          isBack={props.isBack}
          shop_qr={props.shop_qr ?? ""}
        />
      )}
    </div>
  );
}

export default EDDesign;
