import { getIDToken, getUserID } from "../auth";
import axios from "axios";

//User
export const getUserDetails = async () => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    var bodyParams = [];
    bodyParams.push(`access_token=${tokens["AccessToken"]}`);
    const bParameters = bodyParams.join("&");
    const user = await getUserData(
      userId,
      userId,
      tokens["IDToken"],
      bParameters
    );
    return user;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

export const getUser = async (getUserId) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    var bodyParams = [];
    bodyParams.push(`access_token=${tokens["AccessToken"]}`);
    const bParameters = bodyParams.join("&");
    const user = await getUserData(
      userId,
      getUserId,
      tokens["IDToken"],
      bParameters
    );
    return user;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

//AXIOS
const getUserData = async (userId, get_user_id, token, bodyParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_EDSHOP_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/user/get_user" +
      "?" +
      `user_id=${userId}` +
      "&" +
      `get_user_id=${get_user_id}`;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let user = response["data"]["result"];
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editUser = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const user = await edit_user_req(userId, bodyParameters, tokens["IDToken"]);
    return user;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const edit_user_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_EDSHOP_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/user/edit_user" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editUserImg = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await edit_user_img_req(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const edit_user_img_req = (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_EDSHOP_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/user/edit_user_image" +
      "?" +
      `user_id=${user_id}`;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        // console.log("S3_URL_SYNC_RESULT", result);
        resolve(result);
      })
      .catch((error) => {
        // console.log("S3_URL_SYNC_FAILED", error);
        reject(error);
      });
  });
};

export const deleteUserAccount = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const response = await delete_user_account_req(
      bodyParameters,
      tokens["IDToken"]
    );
    return response;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const delete_user_account_req = async (bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_EDSHOP_GATEWAY_URL;
    const api =
      apiEndpoint + "/prod" + "/user/delete_user" + "?" + `user_id=${user_id}`;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(api, bodyParameters, { headers })
      .then((response) => {
        let deleted = response["data"]["result"];
        resolve(deleted);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUserAccount = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const user = await update_account_req(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return user;
  } catch (error) {
    console.log("ER", error);
    throw new Error(error.response.data.error);
  }
};

const update_account_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_EDSHOP_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/user/edit_account" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
