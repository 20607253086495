import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import QRCode from "react-qr-code";

//CSS
import "./ED_Row.css";
//img
import cdLogo from "../../../../assets/img/ic_compactdisk.png";
//about
import about from "../../../../assets/about/about.json";
//LINK
//✅logged in
//https://edshop.songdrop.uk
//ED_Row
function ED_Row(props) {
  //
  //VIEW
  return (
    <div className="ed_row">
      <div className="flex">
        <div
          className="img_data"
          onClick={() => orderBtnPressed(props.album.album_id)}
        >
          <div className="flip">
            <div className="flip-inner">
              <div className="flip-front">
                <img alt="" src={props.album.cover_img_url} />
              </div>
              <div className="flip-back">
                <div className="qrbox">
                  <div className="qrcode">
                    <QRCode
                      id="QRCode"
                      value={generateQrCode(props.album.album_id)}
                      size={88}
                    />
                  </div>
                  <p className="scanme">SCAN ME</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="title-holder">
          <a className="title" href={`/ordered/${props.album.album_id}`}>
            {props.album.title}
            {props.album.explicit == 1 ? "🅴" : ""} - {props.album.artist}
          </a>
          <div className="year">
            {setYear(props.album.released_at)} - {props.album.country}
          </div>
          <div className="year">{props.album.tag}</div>
          <div className="">{props.album.genres}</div>
          <div className="">{props.album.location}</div>
        </div>
      </div>

      <div className="place-order">
        <button
          className="_order-btn_"
          onClick={() => orderBtnPressed(props.album.album_id)}
        >
          Place Order for ED
        </button>
        <br />
      </div>
    </div>
  );

  //return: RELEASE_YEAR
  function setYear(releaseDate) {
    if (isEmpty(releaseDate)) {
      return "";
    }
    let year = new Date(releaseDate).getFullYear();
    return year;
  }

  //CREATE: QR url value
  function generateQrCode(album_id) {
    //
    if (!isEmpty(album_id)) {
      return `${about.qr_url}/${album_id}`;
    } else {
      return "";
    }
  }

  //Go-to: ORDER_ED
  function orderBtnPressed(album_id) {
    const urlPath = `/ordered/${album_id}`;
    window.location.href = urlPath;
  }
}
export default ED_Row;
