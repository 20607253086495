import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
//API
// import { signIn } from "../../../../utils/apis/unauth";
// import { deleteUserAccount } from "../../../../utils/apis/api/auth_api_user";
//CSS
import "./DeleteAccount.css";

//assets
import deleteaccjson from "../../../../assets/json/delete_account.json";

//LINK
//✅logged in
//DeleteAccount class
function DeleteAccount(props) {
  //
  const [acc_delete_in_progress, setAccDelVisible] = useState(false);

  //Delete in progress
  const [isApiWorking, setApiWorking] = useState(false);

  //DELETE_FORM
  //Pass to confirm delete request
  const [email, setEmail] = useState(props.user.email);
  const [password, confirmPassword] = useState("");
  //Content to fill
  const [reasonIndex, setLeavingReasonIndex] = useState(-999);
  const [reasonText, setLeavingReasonText] = useState("");
  const [acknowledged, setAcknowledged] = useState(false);

  //API call: Submit Login
  const handleDeleteSubmit = async (event) => {
    event.preventDefault();
    setApiWorking(true);
    setApiError("");

    const _user_id = props.user.user_id;
    const _email = email;
    const _pass = password;
    const _username = props.user.username;
    const _usertype = props.user.usertype;
    const _reason_type = reasonIndex;
    const _reason_desc = reasonText;

    // try {
    //   //sign in first
    //   await signIn(_email, _pass);
    //   var bodyParams = [];
    //   bodyParams.push(`user_id_to_delete=${_user_id}`);
    //   bodyParams.push(`email=${_email}`);
    //   bodyParams.push(`username=${_username}`);
    //   bodyParams.push(`usertype=${_usertype}`);
    //   bodyParams.push(`reason_type=${_reason_type}`);
    //   bodyParams.push(`reason_desc=${_reason_desc}`);
    //   let parameters = bodyParams.join("&");
    //   //✅Delete success -> go to goodbye
    //   try {
    //     await deleteUserAccount(parameters);
    //     alert(`Your account has been deleted!\nThanks for using SongDrop!`);
    //     await Auth.signOut();
    //     //API: DO CLEAR_UP_DB
    //     window.location.href = "/goodbye";
    //     setApiWorking(false);
    //   } catch (error) {
    //     setApiWorking(false);
    //     setApiError(error.message);
    //     //❌Error: Delete error
    //     alert(`Delete user request error:\n ${error}`);
    //   }
    // } catch (error) {
    //   setApiWorking(false);
    //   setApiError(error.message);
    //   //❌Error: Singin error
    //   alert("Signin error", error.message);
    // }
  };

  //VIEW
  return (
    <div className="delete-body">
      {!acc_delete_in_progress && (
        <button
          className="update-acc-btn"
          onClick={() => setAccDelVisible(!acc_delete_in_progress)}
        >
          Delete account
        </button>
      )}
      {acc_delete_in_progress && (
        <form className="delete-content" onSubmit={handleDeleteSubmit}>
          <div className="delete-title">{deleteaccjson["title"]}</div>
          <div className="delete-box">
            <h1>{deleteaccjson["subtitle"]}</h1>
            <h2>{deleteaccjson["consequences"]}</h2>
            <h3>{deleteaccjson["faq"]}</h3>
            {buildFaqListUI()}
            <div className="reason-title">{deleteaccjson["reason"]}</div>
            <div className="reason-list">
              {buildReasonListUI()}
              {reasonIndex === deleteaccjson["reason-types"].length - 1 && (
                <textarea
                  placeholder="Reason to leave"
                  value={reasonText}
                  onChange={(e) => setLeavingReasonText(e.target.value)}
                />
              )}
            </div>
          </div>
          <div className="password-field">
            <div className="pass-title">{deleteaccjson["type-pass"]}</div>
            <input
              className="pass-input"
              type="password"
              placeholder="••••••••"
              value={password}
              onChange={(e) => confirmPassword(e.target.value)}
            />
          </div>
          <div className="delete_terms_accepted_row">
            <input
              type="checkbox"
              value={acknowledged}
              onChange={() => toggleCheckbox()}
            />
            <p>{deleteaccjson["acknowledge"]}</p>
          </div>
          <div className="save">
            <br />
            {/* API_WORKING */}
            {isApiWorking && (
              <div className="stat-load">
                <div className="stat-loader">
                  <i className={apiFaIcon} />
                </div>
              </div>
            )}
            {/* API_NOT_WORKING */}
            {!isApiWorking && (
              <button
                className="delete-acc-btn"
                disabled={!isDeleteBtnEnabled()}
                onClick={(e) => updateData(e)}
              >
                Delete Account
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  );

  //BUILD: Faq list
  function buildFaqListUI() {
    var tableData = [];
    deleteaccjson["faq-list"].map((item, index) => {
      tableData.push(
        <a key={index} href={item.link} className="faq-row">
          {item.title}
        </a>
      );
    });
    return tableData;
  }

  //BUILD: Reasons for leaving
  function buildReasonListUI() {
    var tableData = [];
    deleteaccjson["reason-types"].map((item, index) => {
      tableData.push(
        <div key={index} className="reason-row">
          <input
            type="checkbox"
            checked={reasonIndex === index ? true : false}
            onChange={() => reasonIndexChecked(index)}
          />
          <p>{item}</p>
        </div>
      );
    });
    return tableData;
  }

  //SET: Delete button enabled/disabled
  function isDeleteBtnEnabled() {
    return !isEmpty(reasonText) && !isEmpty(password) && acknowledged;
  }

  //TOGGLE: checkbox: Does user acknowledged deleting
  function toggleCheckbox() {
    setAcknowledged(!acknowledged);
  }

  //ON-CHANGE: checkbox -> tell why you are leaving
  function reasonIndexChecked(index) {
    setLeavingReasonIndex(index);
    let lastindex = deleteaccjson["reason-types"].length - 1;
    if (index < lastindex) {
      let type = deleteaccjson["reason-types"][index];
      setLeavingReasonText(type);
    } else {
      //Other selected
      setLeavingReasonText("");
    }
  }
}

export default DeleteAccount;
