import React, { useEffect, useRef, useState } from "react";

//json
import qrInfo from "../../assets/json/album_qr_info.json";

//
function InfoBar(props) {
  //VIEW
  return (
    <div>
      <div
        style={{
          fontSize: "12px",
          padding: "6px",
          border: "1px solid lightgray",
        }}
      >
        <p
          style={{
            whiteSpace: "pre-line",
          }}
        >
          <i
            className="fa fa-info-circle"
            style={{
              marginRight: "6px",
            }}
          />
          {qrInfo.notice}
        </p>
      </div>
    </div>
  );
}

export default InfoBar;
