import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import Attachments from "./Attachments";
//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
//img
import gofile from "../../../../assets/img/gofile_logo.png";

//CSS
import "./SendReply.css";

//SendReply
function SendReply(props) {
  //
  const [faReply, setFaReply] = useState(props.faReply ?? "fa fa-reply");
  const [faTitle, setFaTitle] = useState(props.faTitle ?? "Reply to sender:");

  //reply
  const [replyText, setReplyText] = useState("");
  const [reply_attachments, setReplyAttachments] = useState([]);
  //+new url attachment
  const [attachment_url, setAttachmentURL] = useState("");

  const sendMessage = async (event) => {
    event.preventDefault();
    props.sendReply({
      text: replyText,
      attachments: reply_attachments.join(","),
    });
  };

  //VIEW
  return (
    <div className="reply_send">
      <div className="input-container">
        {/* REPLY_TO_MESSAGE */}
        <p className="reply_title">
          <i className={faReply} /> {faTitle}
        </p>
        {/* REPLY_TEXTBOX */}
        <div className="input-field">
          {/* REPLY_TEXT */}
          <textarea
            id="chatinputtxt"
            placeholder="Type something..."
            onChange={(e) => textFieldChanged(e.target.value)}
            value={replyText}
          ></textarea>
          {/* REPLY_ATTACHMENTS */}
          <div className="attachments">
            <Attachments
              attachments={reply_attachments}
              removeURL={removeURL}
              canDelete={true}
            />
          </div>
          {/* ADD_NEW_ATTACHMENTS */}
          <div className="attachments_field">
            <div className="att">
              <i className="fa fa-paperclip" />
              <input
                placeholder="Attachment URL(https://...)"
                value={attachment_url}
                onChange={(e) => onChangeURL(e.target.value)}
              />
              <button onClick={() => addURL()}>
                +Add <i className="fa fa-paperclip" />
              </button>
              <button className="gofile_btn" onClick={() => openGoFile()}>
                <img alt="" src={gofile} title="GoFile" />
              </button>
            </div>
            <div className="att">
              Use Gofile or other file-sharing service and add URL link as an
              attachment.
            </div>
          </div>
        </div>
        {/* SEND_MESSAGE_REPLY_BUTTON + LOADING_INDICATOR */}
        <div className="button-field">
          {props.isApiWorking === true && (
            <button disabled={true}>
              <i className={apiFaIcon}></i>
            </button>
          )}
          {props.isApiWorking === false && (
            <button
              onClick={sendMessage}
              disabled={isEmpty(replyText) || replyText === "\n"}
            >
              Send
            </button>
          )}
        </div>
      </div>
    </div>
  );

  //UPDATE: Input-TEXT
  function textFieldChanged(text) {
    //set text
    setReplyText(text);
    //resize inputfield
    resizeInputField();
  }

  //INPUT_TEXT
  function clearInputField() {
    setReplyText("");
    const element = window.document.getElementById("chatinputtxt");
    element.style.height = "30px";
  }

  //INPUT_TEXT_RESIZE
  function resizeInputField() {
    const element = window.document.getElementById("chatinputtxt");
    element.style.height = "5px";
    element.style.height = element.scrollHeight + "px";
  }

  //OPEN_GOFILE_WEBSITE
  function openGoFile() {
    var url = "https://gofile.io";
    window.open(url, "_blank");
  }

  //CHANGE_ATTACHMENT_URL_TEXT
  function onChangeURL(url) {
    setAttachmentURL(url);
  }

  //ADD_URL_ATTACHMENT
  function addURL() {
    let att_urls = reply_attachments;
    if (isURLValid(attachment_url)) {
      if (!att_urls.includes(attachment_url)) {
        //append new element
        att_urls.push(attachment_url);
        //--->SET_NEW_ARRAY_OF_LINKS
        setReplyAttachments(att_urls);
      } else {
        alert(`${attachment_url} is already added.`);
      }
      //reset url input field
      setAttachmentURL("");
    } else {
      alert("Please, enter a valid URL starting with https://..");
    }
  }

  //REMOVE_URL_ATTACHMENT
  function removeURL(url) {
    console.log(url);
    let att_urls = reply_attachments;
    var urls = att_urls.filter(function (str) {
      return str.indexOf(url) === -1;
    });
    //--->SET_NEW_ARRAY_OF_LINKS
    setReplyAttachments(urls);
  }

  //IS.URL.Valid tells if the entered String is a valid URL
  function isURLValid(str) {
    var urlregex =
      /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    return urlregex.test(str);
  }
}

export default SendReply;
