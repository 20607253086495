import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";

import EDPriceBox from "./EDPriceBox";

//PriceSetter
function PriceSetter(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="price-setter-body">
      {/* ED_PRICES */}
      <EDPriceBox user={props.user} />
    </div>
  );
}

export default PriceSetter;
