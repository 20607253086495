import React, { useEffect, useState } from "react";
import AuthenticatedRoute from "../../../components/AuthenticatedRoute";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import useLocalStorage from "../../../components/useLocalStorage";
import isEmpty from "../../../components/isEmpty";
import Footer from "../../../components/Footer/Footer";
import { getUserDetails } from "../../../utils/apis/api/auth_api_user";

//JS
import DashboardLoading from "./DashboardLoading";
//HOME->DEFAULT
import Home from "../home/Home";
//SUBROUTES
import OrderED from "../order_ed/OrderED";
import MyOrders from "../myorders/MyOrders";
import OrderedItem from "../myorders/OrderedItem";
import CheckoutED from "../checkout/CheckoutED";
import Messages from "../messages/Messages";
import MessageRoom from "../messages/Message/MessageRoom";
import Account from "../account/Account";
import User from "../user/User";
//Notfond
import NotFound from "../../notfound/NotFoundContainer";
//CSS
import "./Dashboard.css";
//icons
import logoIcon from "../../../assets/img/songdrop-logo-black.png";
import DefaultHeader from "./DefaultHeader";

/////
//LINK
//✅logged in
//https://edshop.songdrop.uk
//Dashboard
function Dashboard(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //
  const [currentURLPath, setUrlPath] = useState("");

  //Login_user_to_download
  const [user, setUser] = useLocalStorage("ed_login_user", null);

  const [messages, setMessages] = useState(0);
  const [alerts, setAlerts] = useState(0);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "EDShop | SongDrop";
    //get subpath
    var urlSubpath = window.location.href.split("/")[3];
    // console.log(path)
    setUrlPath(urlSubpath);
    //api
    // if (isEmpty(user)) {
    //   getUserData();
    // }
    getUser();
  }, []);

  //API: Cognito user logged in
  async function getUser() {
    try {
      //get user details
      const result = await getUserDetails();
      //SUCCESS
      const user = result["user"];
      console.log("USER_GET_SUCCESS");
      setApiWorking(false);
      setUser(user);
    } catch (error) {
      //ERROR
      console.log("USER_GET_ERROR", error, error.message);
      setApiError(error.message);
      setApiWorking(false);
    }
  }

  //VIEW
  return (
    <div className="dashboard-body">
      {/* API_LOADING_USER */}
      {isApiWorking && (
        <DashboardLoading
          isApiWorking={isApiWorking}
          apiError={apiError}
          handleLogout={handleLogout}
        />
      )}
      {/* 🚫API_USER_NOT_FOUND_ERROR */}
      {!isApiWorking && isEmpty(user) && (
        <div>
          <DefaultHeader handleLogout={handleLogout} />
          <NotFound
            title="User not found"
            subtitle="User might been removed or restricted"
          />
        </div>
      )}
      {/* ✅API_USER_FOUND */}
      {!isApiWorking && !isEmpty(user) && (
        <div>
          <div className="head">
            <div className="logo-container">
              <img src={logoIcon}></img>
            </div>
            <div className="admintitle">
              <p style={{ cursor: "default" }}>My EDShop</p>
              {!isEmpty(user) && (
                <a href="/">
                  {user.username}
                  {isEmpty(user.dash_badges) === false
                    ? `(${user.dash_badges})`
                    : ""}
                </a>
              )}
              <button className="logout" onClick={() => handleLogout()}>
                <i className="fa fa-sign-out" />
                Logout
              </button>
            </div>
            {/* ◉ ◉ ◉ NAVIGATION_BAR ◉ ◉ ◉ */}
            <div className="navigation-bar">
              <a
                style={{
                  backgroundColor: currentURLPath.includes("ordered")
                    ? "yellow"
                    : "transparent",
                }}
                href="/ordered?searchtxt=&genre=all_genres&filter_by=nearest&page=1&limit=50"
              >
                +Order ED
              </a>
              <a
                style={{
                  backgroundColor: currentURLPath.includes("myorders")
                    ? "yellow"
                    : "transparent",
                }}
                href="/myorders"
              >
                My Orders
                {isEmpty(user.orders_badges) === false
                  ? `(${user.orders_badges})`
                  : ""}
              </a>
              <a
                style={{
                  backgroundColor: currentURLPath.includes("messages")
                    ? "yellow"
                    : "transparent",
                }}
                href="/messages"
              >
                Messages
                {isEmpty(user.message_badges) === false
                  ? `(${user.message_badges})`
                  : ""}
              </a>
              <a
                style={{
                  backgroundColor: currentURLPath.includes("account")
                    ? "yellow"
                    : "transparent",
                }}
                href="/account"
              >
                Account
                {isEmpty(user.account_badges) === false
                  ? `(${user.account_badges})`
                  : ""}
              </a>
            </div>
          </div>
          {/* 🚩URL_ROUTING */}
          <div className="body">
            <Router>
              <Switch>
                <AuthenticatedRoute
                  exact
                  path="/"
                  component={() => <Home user={user} />}
                />
                <AuthenticatedRoute
                  path="/ordered"
                  component={() => <OrderED user={user} />}
                />
                <AuthenticatedRoute
                  path="/order=:id"
                  component={() => <OrderedItem user={user} />}
                />
                <AuthenticatedRoute
                  path="/myorders"
                  component={() => <MyOrders user={user} />}
                />
                <AuthenticatedRoute
                  path="/checkout"
                  component={() => <CheckoutED user={user} />}
                />
                <AuthenticatedRoute
                  path="/messages"
                  component={() => <Messages user={user} />}
                />
                <AuthenticatedRoute
                  path="/msg=:id"
                  component={() => <MessageRoom user={user} />}
                />
                <AuthenticatedRoute
                  path="/account"
                  component={() => <Account user={user} />}
                />
                <AuthenticatedRoute
                  path="/user=:id"
                  component={() => <User user={user} />}
                />
                {/* Not FOUND */}
                <AuthenticatedRoute
                  path="/msg"
                  component={() => <NotFound user={user} />}
                />
              </Switch>
            </Router>
          </div>
          <br />
        </div>
      )}
      <div></div>
      <Footer />
    </div>
  );

  //Logout Request
  function handleLogout() {
    if (window.confirm("Are you sure you want to logout?")) {
      props.handleLogout();
    }
  }
}

export default Dashboard;
