import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Zoom from "react-medium-image-zoom";
import { URLParser } from "../../../components/URLParser";
import { gbpCurrency } from "../../../components/currencyFormatter";
import { getOrder } from "../../../utils/apis/api/auth_api_order";
import "react-medium-image-zoom/dist/styles.css";
//CSS
import "./CheckoutED.css";
//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

const PayPalOptions = {
  "client-id": "test",
  currency: "GBP",
  intent: "capture",
};

//LINK
//✅logged in
//https://edshop.songdrop.uk/checkout?id=123s
function CheckoutED(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  //
  const [seller, setSeller] = useState(null);
  const [buyer, setBuyer] = useState(null);
  const [allBuyerAddresses, setAllBuyerAddresses] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [itemToSell, setItemToSell] = useState(null);
  const [sellerPayPal, setSellerPayPal] = useState(PayPalOptions);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "EDShop | Checkout";
    //url paths
    let url_paths = URLParser();
    var checkoutId = url_paths["id"] ?? "";
    //
    prepareCheckoutItem(checkoutId);
  }, []);

  //API: Cognito user logged in
  async function prepareCheckoutItem(checkout_id) {
    setApiError("");
    setApiWorking(true);

    //
    try {
      //
      var bodyParams = [];
      bodyParams.push(`order_id=${checkout_id}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      //get user details
      const result = await getOrder(bParameters);
      setApiWorking(false);
      console.log("RESULT", result);
      const user = props.user;
      const addresses = user.addresses;
      setBuyer(user);
      setDeliveryAddress(addresses[0]);
      setAllBuyerAddresses(addresses);
      const order = result["order"];
      setSeller(order["seller"]);
      setItemToSell(order["order"]);
      //✅SUCCESS
      //
    } catch (error) {
      //🚫ERROR
      setApiError(error.message);
      setApiWorking(false);
    }
  }

  //
  function selectAddress(address) {
    setDeliveryAddress(address);
  }

  //VIEW
  return (
    <div className="checkout-body">
      {/* API_LOADING_CONTENT */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {/* API_ERROR */}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="_error">{apiError}</div>
      )}
      {/* PAYMENT_METHOD */}
      <div className="payment_type">
        <p className="title">Select Payment Method:</p>
        <div className="paypal">
          <input className="radio" type="radio" checked />
          <span>
            <i className="fa fa-cc-paypal" />
            <p>PayPal</p>
          </span>
        </div>
      </div>
      {/* POST_TO */}
      <div className="payment_address">
        <p className="title">Post to:</p>
        {buildBuyerAddresslist()}
        <button>Change shipping address</button>
        <button>Add new address</button>
        {/* <button>Cancel</button> */}
      </div>
      <div className="payment_product">
        <p className="title">Review item:</p>
        <table>
          <thead>
            <tr>
              <td>
                <p>Front</p>
                <p>Back</p>
              </td>
              <td>Product</td>
              <td>Price </td>
              <td>Quantity</td>
              <td>Subtotal</td>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(itemToSell) && (
              <tr>
                <td>
                  <p className="_mtitle">Front/Back</p>
                  <div className="img_container">
                    <Zoom>
                      <img
                        className="ed_img"
                        alt=""
                        src={itemToSell.seller_item.front_url}
                      />
                    </Zoom>
                    <Zoom>
                      <img
                        className="ed_img"
                        alt=""
                        src={itemToSell.seller_item.back_url}
                      />
                    </Zoom>
                  </div>
                </td>
                <td>
                  <p className="_mtitle">Product</p>
                  <p className="_rtitle">
                    {itemToSell.seller_item.product_name}
                  </p>
                </td>
                <td>
                  <p className="_mtitle">Price</p>
                  <p className="_rtitle">
                    {gbpCurrency(itemToSell.seller_item.unit_price)}
                  </p>
                </td>
                <td>
                  <p className="_mtitle">Quantity</p>
                  <p className="_rtitle">{itemToSell.seller_item.quantity}</p>
                </td>
                <td>
                  <p className="_mtitle">Subtotal</p>
                  <p className="_rtitle">
                    {gbpCurrency(
                      itemToSell.seller_item.unit_price *
                        itemToSell.seller_item.quantity
                    )}
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!isEmpty(seller) && (
        <div className="payment_product">
          <p className="title">Seller details:</p>

          <div>
            <strong>{seller.name}</strong>
          </div>
          <div>{seller.address}</div>
          <br />
        </div>
      )}
      {!isEmpty(itemToSell) && (
        <div className="payment_total">
          <p className="title">Finish checkout:</p>
          <table>
            <tbody>
              <tr>
                <td>Subtotal(1 item)</td>
                <td>
                  {gbpCurrency(
                    itemToSell.seller_item.unit_price *
                      itemToSell.seller_item.quantity
                  )}
                </td>
              </tr>
              <tr>
                <td>VAT({itemToSell.seller_item.vat}%)</td>
                <td>
                  {gbpCurrency(
                    (itemToSell.seller_item.unit_price *
                      itemToSell.seller_item.quantity *
                      itemToSell.seller_item.vat) /
                      100
                  )}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid lightgray" }}>
                <td>Postage</td>
                <td>{returnPostageFree(itemToSell.seller_item.postage_fee)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Order Total</strong>
                </td>
                <td>
                  <strong>{gbpCurrency(returnTotal(itemToSell))}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          {/* PAYPAL_BUTTON */}
          {/* <div className="paypal_now">
            <PayPalScriptProvider options={sellerPayPal}>
              <PayPalButtons style={{ layout: "horizontal" }} />
            </PayPalScriptProvider>
          </div> */}
        </div>
      )}
    </div>
  );

  //BUILD: Faq list
  function buildBuyerAddresslist() {
    var tableData = [];
    allBuyerAddresses.map((address, index) => {
      tableData.push(
        <div className="address_checkbox">
          <div className="tag_line">
            <p className="tag">SELECTED</p>
            {address.is_primary == "1" && (
              <p className="tag">PRIMARY ADDRESS</p>
            )}
          </div>
          <div className="checkbox_line">
            <input
              type="checkbox"
              checked={address.id === deliveryAddress.id}
              onChange={() => selectAddressAtIndex(index)}
            />
            <p>
              {buyer.name}
              {"\n"}
              {returnLongAddress(address)}
            </p>
          </div>
        </div>
      );
    });
    return tableData;
  }

  function selectAddressAtIndex(index) {
    setDeliveryAddress(allBuyerAddresses[index]);
  }

  function returnTotal(item_to_sell) {
    if (!isEmpty(item_to_sell)) {
      return (
        itemToSell.seller_item.unit_price * itemToSell.seller_item.quantity +
        (itemToSell.seller_item.unit_price *
          itemToSell.seller_item.quantity *
          itemToSell.seller_item.vat) /
          100
      );
    }
  }

  function returnPostageFree(postage_fee) {
    if (postage_fee == 0) {
      return "Free";
    } else {
      return gbpCurrency(postage_fee);
    }
  }

  //
  function returnLongAddress(address) {
    return (
      address["street"] +
      ", " +
      address["city"] +
      ", " +
      address["postcode"] +
      ", " +
      address["country"]
    );
  }
}

export default CheckoutED;
