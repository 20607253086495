import React, { useEffect, useState } from "react";
import passwordValidator from "password-validator";
import { Auth } from "aws-amplify";
import isEmpty from "../../../../components/isEmpty";

//css
import "./ChangePass.css";

//fa icons
const faSuccess = "fa fa-check-circle";
const faError = "fa fa-exclamation-circle";
const faSpin = "fa fa-refresh spin-it";

//assets
import passinfo from "../../../../assets/json/change_password.json";

//passwordValidator
var schema = new passwordValidator();

//ChangePass class
function ChangePass(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  const [apiErrorIndex, setApiErrorIndex] = useState([]);
  const [apiText, setApiText] = useState("");
  const [apiIcon, setApiIcon] = useState("");

  //userinput: PASSWORD
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //EMAIL
  const email = props.user.email;

  //ViewDidLoad
  useEffect(() => {
    //password schema setup
    setupPasswordSchema();
  }, []);

  //Submit Login
  const handleSubmit = async (event) => {
    event.preventDefault();
    //set api to working
    setApiWorking(true);
    //connect to Api
    //remove error
    setApiText("");
    setApiError("");
    setApiErrorIndex([]);

    if (!schema.validate(oldPassword)) {
      setApiError(`Old ${passinfo.password}`);
      setApiErrorIndex([0]);
      setApiWorking(false);
      return;
    }
    //check if password valid
    if (!schema.validate(password)) {
      setApiError(`New ${passinfo.password}`);
      setApiWorking(false);
      setApiErrorIndex([1]);
      return;
    }
    if (password !== confirmPassword) {
      setApiError("Passwords are not matching");
      setApiWorking(false);
      setApiErrorIndex([1, 2]);
      return;
    }
    if (oldPassword == password) {
      setApiError("Your new password cannot match with your old password");
      setApiWorking(false);
      setApiErrorIndex([0, 1, 2]);
      return;
    }
    try {
      setApiText("Changing password...");
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword, password);
      setApiIcon(faSuccess);
      setApiWorking(false);
      setApiText("Your password has been changed");
      setOldPassword("");
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      setApiWorking(false);
      alert(error.message);
    }
  };

  //UPDATE: password value as state
  function handleOldPasswordChanged(password) {
    setOldPassword(password);
  }

  //UPDATE: password value as state
  function handlePasswordChanged(password) {
    setPassword(password);
  }

  //UPDATE: confirm password value as state
  function handleConfirmPasswordChanged(password) {
    setConfirmPassword(password);
  }

  //VIEW
  return (
    <div className="changepass-container">
      {!isEmpty(apiText) && (
        <div>
          <i className={apiIcon} />
          {apiText}
        </div>
      )}
      {!isEmpty(apiError) && (
        <div className="apierror">
          <i className={faError} />
          {apiError}
        </div>
      )}
      <form className="input-container" onSubmit={handleSubmit}>
        <div className="input-field">
          <p>*Old Password</p>
          <input
            style={{ border: showErrorOnInput(0) }}
            value={oldPassword}
            placeholder="••••••••"
            type="password"
            onChange={(e) => handleOldPasswordChanged(e.target.value)}
          />
        </div>
        <div className="input-field">
          <p>*New Password</p>
          <input
            style={{ border: showErrorOnInput(1) }}
            value={password}
            placeholder="••••••••"
            type="password"
            onChange={(e) => handlePasswordChanged(e.target.value)}
          />
        </div>
        <div className="input-field">
          <p>*Confirm New Password</p>
          <input
            style={{ border: showErrorOnInput(2) }}
            value={confirmPassword}
            placeholder="••••••••"
            type="password"
            onChange={(e) => handleConfirmPasswordChanged(e.target.value)}
          />
        </div>
        <div className="confirm-btn">
          {/* API_WORKING */}
          {isApiWorking && (
            <button
              className="btn btn_sign_in btn-primary"
              style={{
                backgroundColor: "transparent",
                color: "darkgray",
              }}
              disabled={true}
            >
              <i className={faSpin} />
            </button>
          )}
          {/* API_NOT_WORKING */}
          {!isApiWorking && (
            <button
              type="submit"
              className="reset-btn"
              disabled={!isSubmitEnabled()}
            >
              Change Password
            </button>
          )}
        </div>
      </form>
    </div>
  );

  function showErrorOnInput(index) {
    if (apiErrorIndex.includes(index)) {
      return "1px solid red";
    }
  }
  //password schema setup
  function setupPasswordSchema() {
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(16) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .not()
      .spaces() // Should not have spaces
      .has()
      .symbols() // Should has symbols
      .is()
      .not()
      .oneOf(["Passw0rd", "Password123"]); // Blacklist these values
  }

  //Is Submission Enabled
  function isSubmitEnabled() {
    if (isApiWorking === true) {
      return false;
    } else {
      return password.length > 0 && confirmPassword.length > 0;
    }
  }
}

export default ChangePass;
