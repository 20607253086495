import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import Moment from "moment";

import ED_Row from "./ED_Row";
import Pagination from "../../../../components/Pagination/Pagination";

//CSS
import "./EDSearchResult.css";

//LINK
//✅logged in
//https://edshop.songdrop.uk/ordered
function EDSearchResult(props) {
  //VIEW
  return (
    <div className="">
      <div className="result-table">{ResultTableUI()}</div>
      <Pagination
        items={props.paginationArray}
        pageSize={props.filterLimit ?? 50}
        currentPage={props.currentPage ?? 1}
        onChangePage={(index) => onChangePage(index)}
      />
    </div>
  );

  //PAGE CHANGE
  function onChangePage(index) {}

  //BUILD: List of EDs
  function ResultTableUI() {
    var tableData = [];
    if (isEmpty(props.search_result)) {
      return;
    }
    props.search_result.forEach((album, index) => {
      tableData.push(<ED_Row key={index} album={album} />);
    });
    return tableData;
  }
}

export default EDSearchResult;
