import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import Linkify from "react-linkify";
import Moment from "moment";
//CSS
import "./MessageRoom.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//DELETE
import _messagesJson from "../../../../assets/dummy/messages.json";

//JS
import Attachments from "../SendReply/Attachments";
import SendReply from "../SendReply/SendReply";

//LINK
//✅logged in
//https://edshop.songdrop.uk/msg=
//Account
function MessageRoom(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);

  //original_message
  const [message, setMessage] = useState(null);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "EDShop | Message";
    //api(message_id)
    var msgId = window.location.href.split("=")[1] ?? "";
    getData(msgId);
  }, []);

  //API: call data
  async function getData(msgId) {
    var queryParams = [];
    // queryParams.push(`user_id=${user_id}`);
    queryParams.push(`msg=${msgId}`);
    const qParameters = queryParams.join("&");

    setApiWorking(true);
    setTimeout(function () {
      let msg = _messagesJson.find((msg) => msg.id === msgId);
      setMessage(msg);
      // //api->stop
      setApiWorking(false);
    }, 1000);
  }

  //Submit New Message
  const sendReply = async (reply) => {
    setApiWorking(true);
    console.log("reply", reply);
    setTimeout(function () {
      // //api->stop
      alert("Message was sent!");
      redirectToMessages();
    }, 1000);
    return;
    try {
      var queryParams = [];
      queryParams.push(`user_id=${props.user.user_id}`);
      queryParams.push(`mychat_id=${props.user.mychat_id}`);
      const qParameters = queryParams.join("&");
      var bqueryParams = [];
      bqueryParams.push(`text=${message}`);
      const bParameters = bqueryParams.join("&");
      let result = await createMessage(qParameters, bParameters);
      let msg = result["msg"];
      userMsgs.splice(0, 0, msg);
      setMsgDatasource([...userMsgs]);
      setApiWorking(false);
      setEditing(false);
      //clear text
      clearChatInputField();
    } catch (error) {
      setApiWorking(false);
      alert(error.message);
    }
  };

  //Delete api
  const deleteApiCall = async (messageId) => {
    setApiWorking(true);
    setTimeout(function () {
      setApiWorking(false);
    }, 1000);
    return;
    try {
      var queryParams = [];
      queryParams.push(`mychat_id=${props.user.mychat_id}`);
      queryParams.push(`message_id=${messageId}`);
      const parameters = queryParams.join("&");
      await deleteMessage(parameters);
      userMsgs.forEach((message, index) => {
        if (message.id === messageId) {
          userMsgs.splice(index, 1);
          setMsgDatasource([...userMsgs]);
        }
      });
      setApiWorking(false);
    } catch (error) {
      setApiWorking(false);
      alert(error.message);
    }
  };

  async function tagMessage(messageId, tagType) {
    setApiWorking(true);
    //"archive"   "unarchive"  "unread" "flagged"

    try {
      var queryParams = [];
      queryParams.push(`mychat_id=${props.user.mychat_id}`);
      queryParams.push(`message_id=${messageId}`);

      setApiWorking(false);
    } catch (error) {
      setApiWorking(false);
      alert(error.message);
    }
  }

  //VIEW
  return (
    <div className="message-body">
      {/* LOADING_INDICATOR */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {/* 🚫CONTENT_EMPTY_INDICATOR */}
      {!isApiWorking && isEmpty(message) && (
        <div className="loading_foot">
          <i className="fa fa-check-square" /> Message not found
        </div>
      )}
      {/* ✅CONTENT_LOADED{MESSAGE} */}
      {!isEmpty(message) && (
        <div>
          <div className="nav-bar">
            {/* ->DELETE_MESSAGE */}
            <button
              title="Delete"
              onClick={() => deleteAlertForMsg(message.id)}
            >
              <i className="fa fa-trash" />
            </button>
            {/* ->MARK_UNREAD */}
            <button
              title="Mark as Unread"
              onClick={() => tagUnread(message.id)}
            >
              <i className="fa fa-envelope" />
            </button>
            {/* ->MARK_FLAGGED */}
            <button
              title="Mark as Flagged"
              onClick={() => tagFlagged(message.id)}
            >
              <i className="fa fa-flag" />
            </button>
            {/* ->MARK_ARCHIVED */}
            {message.is_archived == "0" && (
              <button title="Archive" onClick={() => archiveMsg(message.id)}>
                <i className="fa fa-archive" />
              </button>
            )}
            {/* ->MOVE_TO_INBOX */}
            {message.is_archived == "1" && (
              <button
                title="Move to Inbox"
                onClick={() => unarchiveMsg(message.id)}
              >
                <i className="fa fa-inbox" />
              </button>
            )}
          </div>
          {/* MESSAGE_CONTENT */}
          <div className="msg-body">
            {/* MESSAGE_HEADER */}
            <div className="subject">Subject: {message.subject}</div>
            <div className="from">
              {"From: "}
              <a href={`/user=${message.from_id}`}>
                <strong>{message.from}</strong>
              </a>
              <div>Date: {convertDate(message.created_at)}</div>
            </div>
            {/* MESSAGE_BODY+LINKIFY */}
            <div className="msg">
              <strong>Message:</strong>
            </div>
            <Linkify>
              <p className="msgtext">{message.text}</p>
            </Linkify>
            {/* MESSAGE_ATTACHMENT_URLS */}
            {/* REPLY_ATTACHMENTS */}
            <div className="attachments">
              <Attachments
                attachments={message.attachments}
                canDelete={false}
              />
            </div>
            <br />
            <SendReply isApiWorking={isApiWorking} sendReply={sendReply} />
          </div>
        </div>
      )}
    </div>
  );

  //Show alert to delete message
  function deleteAlertForMsg(messageId) {
    if (window.confirm("Are you sure you want to delete this message?")) {
      deleteApiCall(messageId);
    }
  }

  //MARK_MESSAGE: UNREAD
  function tagUnread(messageId) {
    tagMessage(messageId, "unread");
  }

  //MARK_MESSAGE: FLAGGED
  function tagFlagged(messageId) {
    tagMessage(messageId, "flagged");
  }

  //MARK_MESSAGE: ARCHIVE
  function archiveMsg(messageId) {
    tagMessage(messageId, "archive");
  }

  //MARK_MESSAGE: INBOX
  function unarchiveMsg(messageId) {
    tagMessage(messageId, "unarchive");
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }

  //Redirects user to home screen(logged_in_status_to_refresh)
  function redirectToMessages() {
    setTimeout(function () {
      window.location.href = "/messages";
    }, 500);
    setTimeout(function () {
      setApiWorking(false);
    }, 1000);
  }
}

export default MessageRoom;
