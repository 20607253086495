import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import Pagination from "../../../components/Pagination/Pagination";
import { URLParser } from "../../../components/URLParser";
//CSS
import "./Messages.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//DELETE
import _messagesJson from "../../../assets/dummy/messages.json";

//LINK
//✅logged in
//https://edshop.songdrop.uk/Messages
function Messages(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  //
  const [selectedIndex, setSelectedIndex] = useState("");

  //
  //pagination
  const [filterPage, setFilterPage] = useState(0);
  const [filterLimit, setFilterLimit] = useState(50);
  const [paginationArray, setPaginationArray] = useState([
    { id: 1, name: "Item " + 1 },
  ]);

  //messages
  const [messages, setMessages] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllTicked, setAllTicked] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "EDShop | Messages";
    var path = window.location.href.split("?")[1] ?? "";
    setSelectedIndex(path);
    //api
    //url paths
    let url_paths = URLParser();
    var page = url_paths["page"] ?? 1;
    var limit = url_paths["limit"] ?? 50;
    var filter = url_paths["filter"] ?? 0;
    getData(filter, page, limit);
  }, []);

  //API: call data
  async function getData(filter, page, limit) {
    // console.log("data");
    //set api to working
    // alert("SEARCH.");
    setApiWorking(true);

    //QUERY
    var queryParams = [];
    queryParams.push(`filter=${filter}`);
    queryParams.push(`page=${page}`);
    queryParams.push(`limit=${limit}`);
    const qParameters = queryParams.join("&");

    setTimeout(function () {
      setApiWorking(false);
      //filterpageitems
      var pagination = [...Array(10).keys()].map((i) => ({
        id: i + 1,
        name: "Item " + (i + 1),
      }));
      //pagintaion
      setPaginationArray(pagination);
      //msgs
      setMessages(_messagesJson);
    }, 2000);
  }

  const deleteItems = async () => {
    if (isEmpty(selectedIds)) {
      alert("No messages selected to delete.");
      return;
    }
    setApiWorking(true);

    var queryParams = [];
    queryParams.push(`ids=${selectedIds.join(",")}`);
    const qParameters = queryParams.join("&");
    setTimeout(function () {
      console.log("delete success");
      setApiWorking(false);
    }, 2000);
  };

  const markItemsRead = async () => {
    if (isEmpty(selectedIds)) {
      alert("No messages selected to mark as read.");
      return;
    }
    setApiWorking(true);

    var queryParams = [];
    queryParams.push(`ids=${selectedIds.join(",")}`);
    const qParameters = queryParams.join("&");
    setTimeout(function () {
      console.log("mark as read success");
      setApiWorking(false);
    }, 2000);
  };

  const markItemsFlagged = async () => {
    if (isEmpty(selectedIds)) {
      alert("No messages selected to mark as flagged.");
      return;
    }
    setApiWorking(true);

    var queryParams = [];
    queryParams.push(`ids=${selectedIds.join(",")}`);
    const qParameters = queryParams.join("&");
    setTimeout(function () {
      console.log("mark as flagged success");
      setApiWorking(false);
    }, 2000);
  };

  const moveToArchive = async () => {
    if (isEmpty(selectedIds)) {
      alert("No messages selected to move to Archives.");
      return;
    }
    setApiWorking(true);

    var queryParams = [];
    queryParams.push(`ids=${selectedIds.join(",")}`);
    const qParameters = queryParams.join("&");
    setTimeout(function () {
      console.log("move to archives success");
      setApiWorking(false);
      //->reload page
      getData();
    }, 2000);
  };

  //VIEW
  return (
    <div className="messages-body">
      {/* ◉ ◉ ◉ NAVIGATION_BAR ◉ ◉ ◉ */}
      <div className="nav-bar">
        <a
          style={{
            backgroundColor: selectedIndex == "" ? "yellow" : "transparent",
          }}
          href="/messages"
        >
          All
        </a>
        {" | "}
        <a
          style={{
            backgroundColor:
              selectedIndex == "unread=1" ? "yellow" : "transparent",
          }}
          href="/messages?unread=1"
        >
          Unread
        </a>
        {" | "}
        <a
          style={{
            backgroundColor:
              selectedIndex == "flagged=1" ? "yellow" : "transparent",
          }}
          href="/messages?flagged=1"
        >
          Flagged
        </a>
        <button
          title="Delete"
          style={{ marginLeft: "12px" }}
          onClick={() => deleteSelectedMsgs()}
        >
          <i className="fa fa-trash" />
        </button>
        <button title="Mark as Read" onClick={() => readSelectedMsgs()}>
          <i className="fa fa-envelope-open" />
        </button>
        <button title="Mark as Flagged" onClick={() => flagSelectedMsgs()}>
          <i className="fa fa-flag" />
        </button>
        <button title="Archive">
          <i className="fa fa-archive" onClick={() => archiveSelectedMsgs()} />
        </button>{" "}
        <a
          style={{
            backgroundColor:
              selectedIndex == "archived=1" ? "yellow" : "transparent",
          }}
          href="/messages?archived=1"
        >
          Archived
        </a>
      </div>
      {/* LOADING_INDICATOR */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      <div className="messages-content">
        {/* ✅API_CONTENT_FOUND */}
        <table>
          <thead>
            <tr>
              <td style={{ width: "30px" }}>
                <input
                  type="checkbox"
                  className="msg-checkbox"
                  value={isAllTicked}
                  onChange={(e) => selectAllItemInTable(!isAllTicked)}
                />
              </td>
              <td style={{ width: "30px" }}>
                <i className="fa fa-flag" />
              </td>
              <td>From</td>
              <td>Subject</td>
              <td>Date</td>
            </tr>
          </thead>
          <tbody>{ResultTableUI()}</tbody>
        </table>
        {/* LOADING_INDICATOR */}
        {isApiWorking && (
          <div className="loading_foot">Loading messages...</div>
        )}
        {/* 🚫CONTENT_EMPTY_INDICATOR */}
        {!isApiWorking && messages.length === 0 && (
          <div className="loading_foot">
            <i className="fa fa-check-square" /> No messages found
          </div>
        )}
      </div>
      {/* 1️⃣PAGINATION */}
      <Pagination
        items={paginationArray}
        pageSize={filterLimit}
        currentPage={getCurrentPage()}
        onChangePage={(index) => onChangePage(index)}
      />
    </div>
  );

  //BUILD: List of EDs
  function ResultTableUI() {
    var tableData = [];
    if (isEmpty(messages)) {
      return;
    }
    messages.forEach((_msg, index) => {
      tableData.push(
        <tr
          key={index}
          style={{ fontWeight: _msg.is_read === "1" ? "bold" : "" }}
        >
          <td>
            <div>
              <input
                className="msg-checkbox"
                type="checkbox"
                value={selectedIds.includes(_msg.id)}
                onChange={() => selectItem(_msg.id)}
                checked={selectedIds.includes(_msg.id)}
              />
            </div>
          </td>
          <td style={{ color: _msg.is_flagged === "1" ? "red" : "lightgray" }}>
            <div>
              <i className="fa fa-flag" />
            </div>
          </td>
          <td onClick={() => goToMessage(_msg.id)}>
            <div>{_msg.from}</div>
          </td>
          <td onClick={() => goToMessage(_msg.id)}>
            <div className="msg-subject">{_msg.subject}</div>
          </td>
          <td onClick={() => goToMessage(_msg.id)}>
            <div>{convertDate(_msg.created_at)}</div>
          </td>
        </tr>
      );
    });
    return tableData;
  }

  //BUTTON: CONFIRM_DELETE
  function deleteSelectedMsgs() {
    if (window.confirm("Would you like to delete the selected message(s)?")) {
      deleteItems();
    }
  }

  //BUTTON: CONFIRM_READ
  function readSelectedMsgs() {
    if (
      window.confirm("Would you like to mark the selected message(s) as read?")
    ) {
      markItemsRead();
    }
  }

  //BUTTON: CONFIRM_FLAG
  function flagSelectedMsgs() {
    if (
      window.confirm(
        "Would you like to mark the selected message(s) as flagged?"
      )
    ) {
      markItemsFlagged();
    }
  }

  //BUTTON: CONFIRM_ARCHIVE
  function archiveSelectedMsgs() {
    if (
      window.confirm(
        "Would you like to move the selected message(s) into the archives?"
      )
    ) {
      moveToArchive();
    }
  }

  //SELECT_ALL
  function selectAllItemInTable(is_checked) {
    setAllTicked(is_checked);
    if (is_checked) {
      var msg_ids = [];
      messages.forEach((_msg, index) => {
        msg_ids.push(_msg.id);
      });
      setSelectedIds(msg_ids);
    } else {
      setSelectedIds([]);
    }
  }

  //SELECT: selectItem
  function selectItem(msg_id) {
    if (!selectedIds.includes(msg_id)) {
      var msg_ids = [];
      selectedIds.forEach((id) => {
        msg_ids.push(id);
      });
      msg_ids.push(msg_id);
      setSelectedIds(msg_ids);
    } else {
      var msg_ids = [];
      selectedIds.forEach((id) => {
        if (id != msg_id) {
          msg_ids.push(id);
        }
      });
      setSelectedIds(msg_ids);
    }
  }

  //GO-TO: MESSAGE
  function goToMessage(msg_id) {
    window.location.href = `/msg=${msg_id}`;
  }
  //GET: /forum/{cat_id}?p={currentPaginationNumber}
  function getCurrentPage() {
    let url_paths = URLParser();
    var page = url_paths["page"] ?? 1;
    return Math.max(1, parseInt(page));
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }
}

export default Messages;
