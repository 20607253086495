import { getIDToken, getUserID } from "../auth";
import axios from "axios";

export const listPrinters = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const user = await list_printers_req(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return user;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const list_printers_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_EDSHOP_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/order/list_printers" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateEDPrices = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const user = await update_ed_prices_req(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return user;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const update_ed_prices_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_EDSHOP_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/order/update_ed_prices" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const orderItem = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const user = await order_item_req(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return user;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const order_item_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_EDSHOP_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/order/create_order" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getOrder = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const user = await get_order_req(userId, bodyParameters, tokens["IDToken"]);
    return user;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_order_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_EDSHOP_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/order/get_order" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const checkoutOrder = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const user = await checkout_order_req(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return user;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const checkout_order_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_EDSHOP_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/orders/chekout_order" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
