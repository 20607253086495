import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../../components/isEmpty";

//JS
import EDDesign from "./EDDesign";
//CSS
import "./ImageSlider.css";

//LINK
//✅logged in
//https://edshop.songdrop.uk
//ImageSlider
function ImageSlider(props) {
  var slideIndex = 1;

  //ViewDidLoad
  useEffect(() => {
    //title
    pageToNext(props.selected_style);
  }, []);

  //VIEW
  return (
    <div className="w3-content w3-display-container">
      {sliderOptions()}
      <button
        className="w3-button w3-black w3-display-left"
        onClick={() => pageNext(-1)}
      >
        &#10094;
      </button>
      <button
        className="w3-button w3-black w3-display-right"
        onClick={() => pageNext(1)}
      >
        &#10095;
      </button>
      {setTimeout(function () {
        {
          pageToNext(props.selected_style);
        }
      }, 100)}
    </div>
  );

  //BUILD: sliderOptions
  function sliderOptions() {
    var tableData = [];
    // console.log("items,",props.items);
    if (isEmpty(props.items)) {
      return;
    }
    props.items.forEach((item, index) => {
      tableData.push(
        <div key={index} className="mySlides">
          <div
            id="flip-box"
            className="flip-box"
            style={{ width: `${props.width}px`, height: `${props.height}px` }}
          >
            <div
              className="flip-box-inner"
              // onTouchStart={(e) => flipCard(e, "flip-box")}
            >
              <div
                className="flip-box-front"
                style={{
                  width: `${props.width}px`,
                  height: `${props.height}px`,
                }}
              >
                <EDDesign
                  width={props.width}
                  height={props.height}
                  shop_qr={item.shop_qr ?? ""}
                  fontSize={12}
                  trackFontSize={12}
                  album={item.album}
                  isBack={false}
                  edStyle={item.edstyle ?? 0}
                />
              </div>
              <div
                className="flip-box-back"
                style={{
                  width: `${props.width}px`,
                  height: `${props.height}px`,
                }}
              >
                <EDDesign
                  width={props.width}
                  height={props.height}
                  shop_qr={item.shop_qr ?? ""}
                  fontSize={12}
                  trackFontSize={12}
                  album={item.album}
                  isBack={true}
                  edStyle={item.edstyle ?? 0}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
    return tableData;
  }

  //Plus_DIV
  function pageNext(n) {
    let index = parseInt(slideIndex);
    let new_index = (index += n);
    pageToNext(new_index);
  }

  function pageToNext(n) {
    slideIndex = n;
    var i;
    var x = document.getElementsByClassName("mySlides");
    if (isEmpty(props.items)) {
      return;
    }
    if (n >= x.length) {
      slideIndex = 0;
    }
    if (n < 1) {
      slideIndex = 0;
    }
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    if (x.length > 0) {
      x[slideIndex].style.display = "block";
    }
    //->
    props.changedIndex(n);
  }
}
export default ImageSlider;
