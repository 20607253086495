import React, { useEffect, useRef, useState } from "react";

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//CSS
import "./Rating.css";

//EDItem
function Rating(props) {
  //
  const [isApiWorking, setApiWorking] = useState(false);

  const [star, setStar] = useState(0);
  const [description, setDescription] = useState("");

  //ViewDidLoad
  useEffect(() => {}, []);

  //SEND_RATING_API
  const sendRating = async () => {
    //CANNOT_DELETE_PRIMARY_ADDRESS
    if (address_to_edit.is_primary == "1") {
      alert(
        "You cannot delete your primary address. Please change your primary address, before deleting this address."
      );
      return;
    }
    setApiWorking(true);
    //
    var queryParams = [];
    const qParameters = queryParams.join("&");
    setTimeout(function () {
      console.log("ADDRESS_DELETED");
      setApiWorking(false);
      // window.location.href = "/account?edit=1";
    }, 2000);
  };
  //VIEW
  return (
    <div className="rating-box">
      <p>Rate your seller experience</p>
      <fieldset class="rating">
        <input type="radio" id="star5" name="rating" value="5" />
        <label class="full" for="star5" title="Awesome - 5 stars"></label>
        <input type="radio" id="star4half" name="rating" value="4 and a half" />
        <label
          class="half"
          for="star4half"
          title="Pretty good - 4.5 stars"
        ></label>
        <input type="radio" id="star4" name="rating" value="4" />
        <label class="full" for="star4" title="Pretty good - 4 stars"></label>
        <input type="radio" id="star3half" name="rating" value="3 and a half" />
        <label class="half" for="star3half" title="Meh - 3.5 stars"></label>
        <input type="radio" id="star3" name="rating" value="3" />
        <label class="full" for="star3" title="Meh - 3 stars"></label>
        <input type="radio" id="star2half" name="rating" value="2 and a half" />
        <label
          class="half"
          for="star2half"
          title="Kinda bad - 2.5 stars"
        ></label>
        <input type="radio" id="star2" name="rating" value="2" />
        <label class="full" for="star2" title="Kinda bad - 2 stars"></label>
        <input type="radio" id="star1half" name="rating" value="1 and a half" />
        <label class="half" for="star1half" title="Meh - 1.5 stars"></label>
        <input type="radio" id="star1" name="rating" value="1" />
        <label class="full" for="star1" title="Sucks big time - 1 star"></label>
        <input type="radio" id="starhalf" name="rating" value="half" />
        <label
          class="half"
          for="starhalf"
          title="Sucks big time - 0.5 stars"
        ></label>
      </fieldset>
      <textarea
        placeholder="Give us a feedback on this seller"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <div className="btn-holder">
        {/* API_WORKING */}
        {isApiWorking && (
          <div className="stat-load">
            <div className="stat-loader">
              <i className={apiFaIcon} />
            </div>
          </div>
        )}
        {/* API_NOT_WORKING */}
        {!isApiWorking && <button onClick={sendRating}>Send Rating</button>}
      </div>
    </div>
  );
}

export default Rating;
