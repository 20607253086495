import React, { useEffect, useState } from "react";

//css
import "./DashboardLoading.css";

//icons
import logoIcon from "../../../assets/img/songdrop-logo-black.png";

//DefaultHeader
function DefaultHeader(props) {
  //VIEW
  return (
    <div className="dashboard-body">
      <div className="head">
        <div className="logo-container">
          <img src={logoIcon}></img>
        </div>
        <div className="admintitle">
          <p style={{ cursor: "default" }}>My EDShop</p>
          <button className="logout" onClick={() => handleLogout()}>
            <i className="fa fa-sign-out" />
            Logout
          </button>
        </div>
      </div>
    </div>
  );

  //Logout Request
  function handleLogout() {
    if (window.confirm("Are you sure you want to logout?")) {
      props.handleLogout();
    }
  }
}

export default DefaultHeader;
