import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";

//LINK
//✅logged in
//https://edshop.songdrop.uk/
//Postage
function Postage(props) {
  //
  //
  const [isApiWorking, setApiWorking] = useState(true);
  const [album, setAlbum] = useState(null);
  //
  //
  //
  //
  //ViewDidLoad
  useEffect(() => {}, []);
  //
  //
  //
  //
  //
  //VIEW
  return (
    <div className="order_postage">
      <p>
        <strong>Postage:</strong>
      </p>
      {isEmpty(props.selected_printer) && <p>-</p>}
      {!isEmpty(props.selected_printer) && (
        <div>
          <p>{props.selected_printer["seller_prices"]["postage_fee"]}</p>
          <p>{props.selected_printer.delivery_time}</p>
          <p style={{ fontSize: "12px" }}>
            From: {props.selected_printer.location}
          </p>
        </div>
      )}
    </div>
  );
}

export default Postage;
