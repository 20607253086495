import React from "react";

//CSS
import "./Footer.css";

//assets
import about from "../../assets/about/about.json";

//Footer
function Footer(props) {
  return (
    <div className="footer-wrapper">
      <p>
        © {about.year} {about.name}. All rights reserved. {about.version} |
        contact: {about.contact}
      </p>
      <a title="TERMS AND USAGE" href={about.terms_url}>
        Terms and Usage
      </a>
      <a title="EDShop TERMS AND USAGE" href={about.edshop_terms_url}>
        EDShop Terms and Usage
      </a>
      <a title="PRIVACY POLICY" href={about.privacy_url}>
        Privacy Policy
      </a>
      <a title="FAQ" href={about.faq_url}>
        FAQ
      </a>
      <a title="Copyright Claim" href={about.copyright_url}>
        Copyright Claim
      </a>
      {props.contact !== undefined && (
        <a title={`mail-to:${about.contact}`} onClick={() => contactClicked()}>
          Contact
        </a>
      )}
    </div>
  );

  //GO-TO: Email Contact
  function contactClicked() {
    const title = `Contact us via email, and send your oppinion.`;
    if (window.confirm(title)) {
      window.location.href = `mailto:${about.contact}?subject=&body=`;
    }
  }
}

export default Footer;
