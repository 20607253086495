import React from "react";
import isEmpty from "../../../../components/isEmpty";

//css
import "./Attachments.css";

//Attachments
function Attachments(props) {
  //VIEW
  return (
    <div>
      {isEmpty(props.attachments) && (
        <div style={{ opacity: "0.3", fontStyle: "italic" }}>
          <i className="fa fa-paperclip" /> No attachments
        </div>
      )}
      {!isEmpty(props.attachments) &&
        buildedAttachmentList(props.canDelete ?? false)}
    </div>
  );

  function buildedAttachmentList(canDelete) {
    var tableData = [];
    if (isEmpty(props.attachments)) {
      return;
    }
    props.attachments.forEach((url, index) => {
      tableData.push(
        <div className="attach" key={index}>
          <a className="link" href={url} target="_blank">
            <i className="fa fa-paperclip" />
            {url}
          </a>
          {canDelete === true && (
            <button onClick={() => removeURL(url)}>
              <i className="fa fa-times-circle" />
            </button>
          )}
        </div>
      );
    });
    return tableData;
  }

  //remove action button
  function removeURL(url) {
    props.removeURL(url);
  }
}

export default Attachments;
