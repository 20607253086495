import React, { useEffect } from "react";
import html2canvas from "html2canvas";

import EDDesign from "./item/EDDesign";
//css
import "./SaveQrDesign.css";
//fa

//QrDesign
function SaveQrDesign(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //DOWNLOAD: card
  const downloadCard = (event) => {
    event.preventDefault();
    const element = document.getElementById(
      `qrcard${props.album}${props.isBack}`
    );

    //scale image for higher resolution
    element.style.transform = "scale(3,3)";

    html2canvas(element, {
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
    }).then((canvas) => {
      //scale back image
      element.style.transform = "scale(1,1)";
      //escape imgData
      // We need to get the actual file data from the string without the base64 prefix
      let imageBuffer = new Buffer(
        canvas.toDataURL("image/png").replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      );
      const rand = Math.random().toString().substr(2, 8); // 60502138
      const img_data = {
        img: imageBuffer,
        filename: `${rand}` + props.filename,
      };
      props.escapeImgData(img_data);
    });
  };

  return (
    <div className="saveqrdesignbody">
      <form onLoad={downloadCard}>
        <div className="cardcontainer">
          <div id={`qrcard${props.album}${props.isBack}`} className="savecard">
            <EDDesign
              width={props.width}
              height={props.height}
              fontSize={props.fontSize}
              trackFontSize={props.trackFontSize}
              album={props.album}
              isBack={props.isBack}
              edStyle={props.edStyle}
              corsEnabled={true}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default SaveQrDesign;
