import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import Linkify from "react-linkify";
import { updateUserAccount } from "../../../../utils/apis/api/auth_api_user";
//CSS
import "./Profile.css";
//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const apiFaSuccess = "fa fa-check-circle";

//Profile-EDIT
function Profile(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [isApiSuccess, setApiSuccess] = useState(false);

  //ViewDidLoad
  useEffect(() => {}, []);

  const [name, setNameAttr] = useState(props.user.name);
  const [username, setUsernameAttr] = useState(props.user.username);
  const [website, setWebsiteAttr] = useState(props.user.website);
  const [about, setAboutAttr] = useState(props.user.about);

  //API_EDIT_DATA
  const updateData = async (event) => {
    event.preventDefault();
    setApiWorking(true);
    //INPUT_ERROR
    let inputError = checkForInputErrors();
    if (!isEmpty(inputError)) {
      alert(inputError);
      return;
    }
    setApiSuccess(false);
    setApiWorking(true);

    try {
      //queryParams
      var bodyParams = [];
      bodyParams.push(`name=${name}`);
      bodyParams.push(`username=${username}`);
      bodyParams.push(`website=${website || ""}`);
      bodyParams.push(`about=${about || ""}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");

      //
      console.log("bodyParams", bParameters);
      //get user details
      const result = await updateUserAccount(bParameters);
      console.log(result);
      //✅SUCCESS
      setApiSuccess(true);
      // console.log("SELLER_PRICES_UPDATED");
      setApiWorking(false);
      setTimeout(function () {
        setApiSuccess(false);
      }, 3000);
    } catch (error) {
      //🚫ERROR
      console.log("EDS_GET_ERROR", error);
      alert(error);
      setApiWorking(false);
    }
  };

  //VIEW
  return (
    <div className="profile_box">
      <p>Name:</p>
      <input
        placeholder="Name"
        value={name}
        onChange={(e) => setNameAttr(e.target.value)}
      />
      <p>Username:</p>
      <input
        placeholder="Username"
        value={username}
        onChange={(e) => setUsernameAttr(e.target.value)}
      />
      <p>Website:</p>
      <input
        placeholder="Website"
        value={website}
        onChange={(e) => setWebsiteAttr(e.target.value)}
      />
      <p>Account Type:</p>
      <p>
        <strong>{returnAccTypeString(props.user.usertype)}</strong>
      </p>
      <div>About:</div>
      <div>
        <textarea
          placeholder="Describe your activity"
          value={about}
          onChange={(e) => setAboutAttr(e.target.value)}
        />
      </div>
      <div>updated: {props.user.updated_at}</div>
      <div className="save">
        <br />
        {/* API_WORKING */}
        {isApiWorking && (
          <div className="stat-load">
            <div className="stat-loader">
              <i className={apiFaIcon} />
            </div>
          </div>
        )}
        {/* API_NOT_WORKING */}
        {!isApiWorking && (
          <button className="update-acc-btn" onClick={(e) => updateData(e)}>
            {!isApiSuccess && <p>Save</p>}
            {isApiSuccess && <i className={apiFaSuccess} />}
          </button>
        )}
      </div>
    </div>
  );

  //ACCOUNT_TYPES
  function returnAccTypeString(account_type) {
    switch (account_type) {
      case "0":
        return "Artist";
      case "1":
        return "Printing Studio";
      case "2":
        return "Music Store";
      case "3":
        return "Shop";
      default:
        return "user";
    }
  }

  function checkForInputErrors() {
    //check album props are correct
    var error_msg = "";
    //check if album has tracks(not empty)
    // if (isEmpty(prices)) {
    //   error_msg = "You need to add at least 1 price";
    // }
    //check track has empty url or data
    if (isEmpty(name)) {
      error_msg = `Name cannot be empty`;
    }
    if (isEmpty(username)) {
      error_msg = `Username cannot be empty`;
    }
    return error_msg;
  }
}

export default Profile;
