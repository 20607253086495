import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { CopyToClipboard } from "react-copy-to-clipboard";

//JS
import Rating from "./rating/Rating";

//CSS
import "./OrderedItem.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//JS
import SendReply from "../messages/SendReply/SendReply";
import NotFound from "../../notfound/NotFoundContainer";
//DELETE
import _ordersJson from "../../../assets/dummy/orders.json";

//LINK
//✅logged in
//https://edshop.songdrop.uk/itemorder
//Messages
function OrderedItem(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);

  //orders
  const [orderedItem, setOrderedItem] = useState(null);
  //track_no
  const [copiedTrackNo, setCopiedTrackNo] = useState("");

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "EDShop | Order";
    var order_id = window.location.href.split("=")[1] ?? "";
    console.log("order_id", order_id);
    //api
    getData(order_id);
  }, []);

  //API: call data
  async function getData(order_id) {
    // console.log("data");
    //set api to working
    // alert("SEARCH.");

    var queryParams = [];
    queryParams.push(`order_id=${order_id}`);
    const qParameters = queryParams.join("&");

    document.title = `EDShop | Order No. ${order_id}`;

    setApiWorking(true);

    setTimeout(function () {
      setApiWorking(false);
      //msgs
      setOrderedItem(_ordersJson[0]);
    }, 2000);
  }

  //Submit New Message
  const sendMessage = async () => {
    setApiWorking(true);
    setApiError("");

    try {
      //queryParams
      var queryParams = [];
      queryParams.push(`user_id=${props.user.user_id}`);
      queryParams.push(`mychat_id=${props.user.mychat_id}`);
      const qParameters = queryParams.join("&");
      //bqueryParams
      var bodyParams = [];
      bodyParams.push(`text=${message}`);
      const bParameters = bodyParams.join("&");
      let result = await createMessage(qParameters, bParameters);
      let msg = result["msg"];
      userMsgs.splice(0, 0, msg);
      setMsgDatasource([...userMsgs]);
      setApiWorking(false);
      setEditing(false);
      //clear text
      clearChatInputField();
    } catch (error) {
      setApiWorking(false);
      setApiError(error.message);
      alert(error.message);
    }
  };

  //UPDATE_ORDER_STATUS
  const updateOrderStatus = async (order_id, new_status) => {
    setApiWorking(true);
    setTimeout(function () {
      setApiWorking(false);
    }, 2000);
    return;
  };

  //VIEW
  return (
    <div className="ordered-item-body">
      {/* API_LOADING_ITEM */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {/* ✅API_ORDERED_ITEM_FOUND */}
      {!isEmpty(orderedItem) && (
        <div className="orders-content">
          <table>
            <tbody>{ResultTableUI()}</tbody>
          </table>
        </div>
      )}
      {/* 🚫API_ORDERED_ITEM_NOT_FOUND_ERROR */}
      {!isApiWorking && isEmpty(orderedItem) && (
        <div>
          <NotFound
            title="Ordered Item Not found"
            subtitle="There was an error while requesting the item"
          />
        </div>
      )}
    </div>
  );

  //BUILD: List of EDs
  function ResultTableUI() {
    var tableData = [];
    if (isEmpty(orderedItem)) {
      return;
    }
    let _order = orderedItem;
    tableData.push(
      <tr style={{ fontWeight: _order.in_progress === "1" ? "bold" : "" }}>
        <td>
          <div className="order-row">
            {/* ORDERED_FROM_USER */}
            <div className="header">
              <p style={{ float: "left" }}>
                Ordered from: <a href={`/user=${_order.from}`}>{_order.from}</a>
              </p>
              <p
                style={{
                  float: "right",
                  fontSize: "11px",
                  marginRight: "6px",
                  paddingTop: "1px",
                }}
              >
                <strong>{convertDate(_order.created_at)}</strong>
              </p>
            </div>
            {/* ORDERED_ITEM_BODY */}
            <div className="body">
              {/* ->ORDER_PROGRESS_STATUS_BAR */}
              <div className="order-progress">
                {createProgressBar(_order.progress, _order.is_refunded)}
              </div>
              {/* ->ORDERED_ITEM_DETAILS */}
              <div className="order-image">
                {/* ----->ORDERED_ITEM_IMAGE */}
                <img src={_order.img_url} alt="" />
                {/* ----->ORDERED_ITEM_TEXT */}
                <div className="order-text">{_order.text}</div>
              </div>
              {/* ----->ORDERED_ITEM_ID */}
              <div className="order-id">
                Order Id: <strong>{_order.id}</strong>
                {/* ----->ORDERED_ITEM_INVOICE */}
                <a href={_order.invoice} target="_blank">
                  <i className="fa fa-file-text" />
                  Invoice
                </a>
              </div>
              <div className="buttons">
                {/* ADD_TRACKING_NUMBER */}
                <CopyToClipboard
                  text={_order.tracking_number}
                  onCopy={() => copyTrackingNumber(_order.tracking_number)}
                >
                  <i className="fa fa-truck" />
                </CopyToClipboard>
                <input
                  disabled={props.user.usertype == "2" ? false : true}
                  placeholder={
                    props.user.usertype == "2"
                      ? "Tracking number"
                      : "No tracking number"
                  }
                  value={_order.tracking_number}
                ></input>
                {/* CHANGE_ORDER_STATUS_SELECTOR */}
                <select
                  className="order_status_select"
                  disabled={props.user.usertype == "2" ? false : true}
                  value={_order.status}
                >
                  <option value={"1"}>Ordered</option>
                  <option value={"2"}>Dispatched</option>
                  <option value={"3"}>Delivered</option>
                  <option value={"4"}>Refunded</option>
                </select>
                {props.user.usertype == "2" && <button>Update</button>}
                {/* SEND_MESSAGE_BUTTON */}
                {/* <button onClick={() => sendOrderMessage(_order.id)}>
                  Message
                </button> */}
                {/* COPY_TRACKING_NUMBER_BUTTON */}
                {!isEmpty(_order.tracking_number) && (
                  <CopyToClipboard
                    text={_order.tracking_number}
                    onCopy={() => copyTrackingNumber(_order.tracking_number)}
                  >
                    <button>
                      <i
                        className={
                          _order.tracking_number !== copiedTrackNo
                            ? "fa fa-truck"
                            : "fa fa-check"
                        }
                      />
                      {_order.tracking_number === copiedTrackNo
                        ? "Copied"
                        : "Copy"}
                    </button>
                  </CopyToClipboard>
                )}
                {props.user.usertype != "2" && (
                  <button
                    className="mark_as_delivered_btn"
                    onClick={() => markAsDelivered()}
                  >
                    <i className="fa fa-check-circle" />
                    Mark as Delivered
                  </button>
                )}
              </div>
              <div className="reply_box">
                <SendReply
                  faReply={"fa fa-info-circle"}
                  faTitle={"Have questions?"}
                  isApiWorking={isApiWorking}
                  sendReply={sendMessage}
                />
                <Rating />
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
    return tableData;
  }

  //PROGRESS-BAR
  function createProgressBar(order_progress, is_refunded) {
    var steps = [...Array(3).keys()].map((i) => (
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
            <div className="checked">
              <i
                className={
                  is_refunded === "1" ? "fa fa-circle" : "fa fa-check-circle"
                }
                style={{ color: `${accomplished ? "#00bd3f" : "darkgray"}` }}
              />
            </div>
          </div>
        )}
      </Step>
    ));
    return (
      <div>
        <div className="p-bar">
          <ProgressBar
            percent={order_progress}
            filledBackground={`${
              order_progress === 100 ? "#0aff0250" : "#d3d3d5"
            }`}
          >
            {steps}
          </ProgressBar>
        </div>
        <div className="progress-titles">
          <p style={{ textAlign: "left" }}>Ordered</p>
          <p>Dispatched</p>
          {is_refunded !== "1" && (
            <p style={{ textAlign: "right" }}>Delivered</p>
          )}
          {is_refunded === "1" && (
            <p style={{ textAlign: "right" }}>Refunded</p>
          )}
        </div>
      </div>
    );
  }

  //MARK_ORDER_STATUS_AS_DELIVERED
  function markAsDelivered(order_id, new_status) {
    if (window.confirm("Would you like to mark this order as DELIVERED?")) {
      updateOrderStatus(order_id, new_status);
    }
  }

  //GO-TO: ORDER
  function sendOrderMessage(order_id) {
    window.location.href = `/order=${order_id}`;
  }

  //COPY_TRACKING_NUMBER
  function copyTrackingNumber(tracking_number) {
    setCopiedTrackNo(tracking_number);
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }
}

export default OrderedItem;
