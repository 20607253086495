import React, { useState, useEffect } from "react";
import isEmpty from "../../../../components/isEmpty";
import { URLParser } from "../../../../components/URLParser";
//CSS
import "./OrderEDSearchBar.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//OrderEDSearchBar
function OrderEDSearchBar(props) {
  //api_filter
  const [search_text, setSearchText] = useState("");
  const [filterType, setFilterType] = useState("nearest");
  const [filterGenres, setFilterGenres] = useState("all_genres");

  //ViewDidLoad
  useEffect(() => {
    //title
    let url_paths = URLParser();
    setFilterGenres(url_paths["genre"]);
    setSearchText(url_paths["searchtxt"]);
    setFilterType(url_paths["filter_by"]);
  }, []);

  //VIEW
  return (
    <div className="search-bar">
      <div className="d-flex text-left align-items-center w-100">
        <input
          placeholder="Search"
          value={search_text || ""}
          onChange={(e) => changeSearchText(e)}
          onKeyPress={(e) => handleSearch(e)}
        />
        <select
          id="multiple-checkboxes"
          onChange={(e) => didChangeByGenre(e.target.value)}
          value={filterGenres}
        >
          {genreOptionsSelect()}
        </select>
        {/* API_WORKING */}
        {props.isApiWorking && (
          <button className="search-btn" onClick={() => startSearching()}>
            <i className={apiFaIcon} />
          </button>
        )}
        {/* API_NOT_WORKING */}
        {!props.isApiWorking && (
          <button className="search-btn" onClick={() => startSearching()}>
            Search
          </button>
        )}
        <select
          id="multiple-checkboxes"
          onChange={(e) => didChangeSortBy(e.target.value)}
          value={filterType}
        >
          <option value="nearest">Nearest</option>
          <option value="recent">Most recent</option>
          <option value="popularity">Popularity</option>
        </select>
      </div>
    </div>
  );

  //BUILD: genreOptionsSelect
  function genreOptionsSelect() {
    var tableData = [];
    props.genre_options.forEach((genre, index) => {
      tableData.push(
        <option key={index} value={genre.value}>
          {genre.title}
        </option>
      );
    });
    return tableData;
  }

  //Change_search_text
  function changeSearchText(e) {
    setSearchText(e.target.value);
  }

  function handleSearch(e) {
    if (e.key === "Enter") {
      //->SEARCH
    }
    return false;
  }

  function didChangeSortBy(filter_type) {
    setFilterType(filter_type);
  }

  function didChangeByGenre(genre) {
    setSearchText("");
    setFilterGenres(genre);
    //no searchtext if user changes genre to search
  }

  function startSearching() {
    var _txt = search_text;
    var _genres = filterGenres;
    var _filter = filterType;
    if (!isEmpty(search_text)) {
      _genres = "all_genres";
    }
    //->SEARCH
    let search_url = `/ordered?searchtxt=${_txt}&genre=${_genres}&filter_by=${_filter}&page=1&limit=50`;
    props.setSearchURL(search_url);
  }
}

export default OrderEDSearchBar;
