import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";

//CSS
import "./PostageSetter.css";

//PostageSetter
function PostageSetter(props) {
  //ViewDidLoad
  useEffect(() => {
    txtChar = document.getElementById("txtChar");
    txtChar.addEventListener("keypress", isNumberKey, false);
  }, []);

  //update
  function updatePostage(key, value) {
    props.updatePostage(key, value);
  }

  //VIEW
  return (
    <div className="postage">
      <div className="p_title">Postage</div>
      <div className="checkbox">
        <div className="checkbox-wrapper">
          <input
            type="checkbox"
            checked={props.postage_fee === 0}
            onChange={(e) => updatePostage("postage_fee", 0)}
          />
        </div>
        <p style={{ fontWeight: props.postage_fee === 0 ? "bold" : "normal" }}>
          Free for buyer - You pay delivery fee
        </p>
      </div>
      <div className="checkbox">
        <div className="checkbox-wrapper">
          <input
            type="checkbox"
            checked={props.postage_fee !== 0}
            onChange={(e) => updatePostage("postage_fee", 1)}
          />
        </div>
        <div
          style={{ fontWeight: props.postage_fee !== 0 ? "bold" : "normal" }}
        >
          <p>Buyer pays – flat postage </p>
          <i
            className="fa fa-gbp"
            style={{
              marginRight: "4px",
            }}
          />
          <input
            style={{
              display: props.postage_fee === 0 ? "none" : "inline-block",
            }}
            type="number"
            id="txtChar"
            min="1.0"
            step="0.01"
            placeholder={`1.00`}
            value={props.postage_fee}
            onChange={(e) => updatePostage("postage_fee", e.target.value || 1)}
          />
        </div>
      </div>
      <div className="checkbox_">
        <div className="">Delivery Policy URL</div>
        <div>
          <input
            type="text"
            placeholder="https://optional"
            value={props.delivery_policy_url}
            onChange={(e) =>
              updatePostage("delivery_policy_url", e.target.value)
            }
          />
        </div>
      </div>
    </div>
  );

  //INPUT_NUMBER_ONLY
  function isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 46 || (charCode > 31 && (charCode < 48 || charCode > 57))) {
      evt.preventDefault();
      return false;
    }
    return true;
  }
}

export default PostageSetter;
