import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
// import Beta from "./containers/beta/Beta";

//Amplify
import config from "./utils/aws-exports";
import Amplify from "aws-amplify";
Amplify.configure(config);

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
