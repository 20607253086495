import React, { useEffect, useState } from "react";
import useLocalStorage from "../../../components/useLocalStorage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import isEmpty from "../../../components/isEmpty";
import { searchEDs } from "../../../utils/apis/api/public";
import { URLParser } from "../../../components/URLParser";
//CSS
import "./OrderED.css";

import OrderEDSearchBar from "./2search_bar/OrderEDSearchBar";
import EDSearchResult from "./0search_result/EDSearchResult";
import EDItem from "./1ed_item/EDItem";
import InfoBar from "../InfoBar";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//LINK
//✅logged in
//https://edshop.songdrop.uk/ordered
function OrderED(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  const [ed_id_to_order, setEDItemToOrder] = useState("");

  //SEARCH_BAR_GENRES
  const [genre_options, setGenreOptions] = useLocalStorage("genre_search", [
    { title: "All genres(0)", value: "all_genres" },
  ]);
  //SEARCH_RESULT
  const [search_result, setSearchResult] = useState([]);

  //PAGINATION
  const [filterPage, setFilterPage] = useState(0);
  const [filterLimit, setFilterLimit] = useState(50);
  const [paginationArray, setPaginationArray] = useState([
    { id: 1, name: "Item " + 1 },
  ]);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "EDShop | +Order ED";
    //url paths
    let url_paths = URLParser();
    var searchtxt = url_paths["searchtxt"] ?? "";
    var genre = url_paths["genre"] ?? "all_genres";
    var filter_by = url_paths["filter_by"] ?? "";
    var page = url_paths["page"] ?? "0";
    var limit = url_paths["limit"] ?? "50";
    setFilterPage(page);
    setFilterLimit(limit);
    //
    var albumId = window.location.pathname.split("/")[2];
    // console.log("albumId",albumId)
    setEDItemToOrder(albumId);
    //API -> no albumId -> fetch default list of EDs for SALE
    if (isEmpty(albumId)) {
      getData(searchtxt, genre, filter_by, page, limit);
    }
  }, []);

  //API: call data
  async function getData(search_text, genres, filter, page, limit) {
    // console.log("data");
    //set api to working
    // alert("SEARCH.");
    setApiWorking(true);
    setApiError("");

    try {
      //queryParams
      var queryParams = [];
      queryParams.push(`search_text=${search_text}`);
      queryParams.push(`genres=${genres}`);
      queryParams.push(`filter=${filter}`);
      queryParams.push(`page=${page}`);
      queryParams.push(`limit=${limit}`);
      const qParameters = queryParams.join("&");
      //get user details
      const result = await searchEDs(qParameters);
      console.log(result);
      //✅SUCCESS
      setSearchResult(result["eds"]);
      //SEARCH_BAR_HEADER
      setGenreOptions(result["genres"]);
      //RESULT_PAGINATION
      var pagination = [...Array(10).keys()].map((i) => ({
        id: i + 1,
        name: "Item " + (i + 1),
      }));
      setPaginationArray(pagination);
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      console.log("EDS_GET_ERROR", error, error.message);
      setApiError(error.message);
      setApiWorking(false);
    }
  }

  function setSearchURL(search_url) {
    setApiWorking(true);
    setApiError("");
    setTimeout(function () {
      window.location.href = search_url;
    }, 1000);
  }

  //VIEW
  return (
    <div className="ordered-body">
      {/* 🔍SEARCHBAR */}
      <OrderEDSearchBar
        isApiWorking={isApiWorking}
        genre_options={genre_options}
        setSearchURL={setSearchURL}
      />
      {/* API_LOADING_CONTENT */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {/* API_ERROR */}
      {!isEmpty(apiError) && (
        <div className="error" style={{ color: "red" }}>
          {apiError}
        </div>
      )}
      {/* 🚩URL_ROUTING */}
      <div className="body">
        {!isApiWorking && <InfoBar />}
        <Router>
          <Switch>
            <Route
              exact
              path="/ordered"
              component={() => (
                <EDSearchResult
                  search_result={search_result}
                  paginationArray={paginationArray}
                />
              )}
            />
            {!isEmpty(ed_id_to_order) && !isApiWorking && (
              <Route
                path="/ordered/:id"
                component={() => (
                  <EDItem setApiWorking={setApiWorking} user={props.user} />
                )}
              />
            )}
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default OrderED;
