import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

export default function AuthenticatedRoute({ children, ...rest }) {
  const { isAuthenticated } = useAppContext();
  // console.log("AUTH_ROUTE", isAuthenticated);
  return (
    <Route {...rest}>
      {isAuthenticated ? children : <Redirect to={`/welcome`} />}
    </Route>
  );
}
