import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Linkify from "react-linkify";
import Moment from "moment";
import { getUser } from "../../../utils/apis/api/auth_api_user";

//CSS
import "./User.css";

//JS
import NotFound from "../../notfound/NotFoundContainer";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//imgs
import logo_band from "../../../assets/img/band_logo.png";
import printing_studio_logo from "../../../assets/img/printing_studio_logo.png";
import music_store_logo from "../../../assets/img/music_store_logo.png";
import shop_logo from "../../../assets/img/shop_logo.png";

//LINK
//✅logged in
//https://edshop.songdrop.uk/user
//Account
function User(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState(false);

  //props
  const [user, setUser] = useState(null);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "EDShop | User";
    //api
    var userId = window.location.href.split("=")[1] ?? "";
    getUserData(userId);
  }, []);

  //API: Cognito user logged in
  async function getUserData(userId) {
    try {
      //get user details
      const result = await getUser(userId);
      //SUCCESS
      const user = result["user"];
      console.log("USER_GET_SUCCESS", user);
      setApiWorking(false);
      setUser(user);
    } catch (error) {
      //ERROR
      console.log("USER_GET_ERROR", error, error.message);
      setApiError(error.message);
      setApiWorking(false);
    }
  }

  //VIEW
  return (
    <div className="user-body">
      {/* API_LOADING_ITEM */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {/* API_ERROR */}
      {!isEmpty(apiError) && (
        <div
          className="error"
          style={{ color: "red", textAlign: "center", display: "block" }}
        >
          {apiError}
        </div>
      )}
      {/* ✅API_USER_FOUND */}
      {!isEmpty(user) && (
        <div className="user">
          <div className="header">
            <img
              src={
                isEmpty(user.profile_img_url)
                  ? returnAccTypeImg(user.usertype)
                  : user.profile_img_url
              }
            />
            <div className="user_name">
              <div className="name">{user.name}</div>
              <div className="type">{returnAccTypeString(user.user_type)}</div>
              <div className="username">{user.username}</div>
              {!isEmpty(user.website) && (
                <a className="website">
                  <i className="fa fa-globe" />
                  {user.website}
                </a>
              )}
            </div>
          </div>
          <div className="about">
            <div className="title">ABOUT:</div>
            <Linkify>
              <p className="text">{user.about}</p>
              <br />
            </Linkify>
          </div>
          <div className="member">
            <p>Location: {user.location}</p>
            <p>Member since: {convertDate(user.created_at)}</p>
          </div>
        </div>
      )}
      {/* 🚫API_USER_NOT_FOUND_ERROR */}
      {!isApiWorking && isEmpty(user) && (
        <div>
          <NotFound
            title="User Not found"
            subtitle="There was an error while requesting user"
          />
        </div>
      )}
    </div>
  );

  function returnAccTypeString(account_type) {
    switch (account_type) {
      case "0":
        return "Artist";
      case "1":
        return "Printing Studio";
      case "2":
        return "Record Store";
      case "3":
        return "Shop";
      default:
        return "user";
    }
  }

  function returnAccTypeImg(account_type) {
    switch (account_type) {
      case "0":
        return logo_band;
      case "1":
        return printing_studio_logo;
      case "2":
        return music_store_logo;
      case "3":
        return shop_logo;
      default:
        return "";
    }
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString).format("YYYY-MM-DD");
    return date;
  }
}

export default User;
