import React, { useEffect, useState } from "react";

//img
//css
import "./NotFoundContainer.css";
import about from "../../assets/about/about.json";

//fa icons
const title = "404 - Page not found";
const info =
  "The page you are looking for might have been removed or is temporarily unavailable.";
const errorIcon = "fa fa-chain-broken";

//NotFound class
function NotFoundContainer(props) {
  const [_title, setTitle] = useState(props.title ?? title);
  const [_info, setInfo] = useState(props.subtitle ?? info);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "404 | SongDrop.band";
  }, []);

  return (
    <div className="notfound-container">
      <div className="body">
        <div className="header">
          <div className="welcome">
            <p>{_title}</p>
          </div>
        </div>
        <div className="content">
          <i className={errorIcon} aria-hidden="true"></i>
          <p>{_info}</p>
        </div>
      </div>
    </div>
  );
}

export default NotFoundContainer;
