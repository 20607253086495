import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import Pagination from "../../../components/Pagination/Pagination";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Zoom from "react-medium-image-zoom";
//CSS
import "./MyOrders.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//DELETE
import _ordersJson from "../../../assets/dummy/orders.json";
import InfoBar from "../InfoBar";

const order_statuses = [""];
//LINK
//✅logged in
//https://edshop.songdrop.uk/myorders
//Messages
function MyOrders(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  //filter_value:delivered=1 || ""
  const [selectedFilterRef, setSelectedFilterRef] = useState("");
  //pagination
  const [filterPage, setFilterPage] = useState(0);
  const [filterLimit, setFilterLimit] = useState(50);
  const [paginationArray, setPaginationArray] = useState([
    { id: 1, name: "Item " + 1 },
  ]);

  const [orders, setOrders] = useState([]);
  //copied_tracking_number
  const [copiedTrackNo, setCopiedTrackNo] = useState("");
  //

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "EDShop | Orders";
    var filterReference = window.location.href.split("?")[1] ?? "";
    setSelectedFilterRef(filterReference);
    //
    var qr = {};
    window.location.search
      .substring(1)
      .split("&")
      .forEach((p) => {
        qr[p.split("=")[0]] = p.split("=")[1];
      });
    //use
    // console.log(qr);
    //api
    getData();
  }, []);

  //API: call data
  async function getData(filter, page, limit) {
    // console.log("data");
    //set api to working
    // alert("SEARCH.");

    var queryParams = [];
    queryParams.push(`filter=${filter}`);
    queryParams.push(`page=${page}`);
    queryParams.push(`limit=${limit}`);
    const qParameters = queryParams.join("&");

    setApiWorking(true);
    setApiError("");

    setTimeout(function () {
      setApiWorking(false);
      //filterpageitems
      var pagination = [...Array(10).keys()].map((i) => ({
        id: i + 1,
        name: "Item " + (i + 1),
      }));
      //pagintaion
      setPaginationArray(pagination);
      //msgs
      setOrders(_ordersJson);
    }, 2000);
  }

  //UPDATE_ORDER_STATUS
  const updateOrderStatus = async (order_id, new_status) => {
    setApiWorking(true);
    setTimeout(function () {
      setApiWorking(false);
    }, 2000);
    return;
  };

  //VIEW
  return (
    <div className="orders-body">
      {/* ◉ ◉ ◉ NAVIGATION_BAR ◉ ◉ ◉ */}
      <div className="nav-bar">
        <a
          style={{
            backgroundColor: selectedFilterRef == "" ? "yellow" : "transparent",
          }}
          href="/myorders"
        >
          In Progress
        </a>
        {" | "}
        <a
          style={{
            backgroundColor:
              selectedFilterRef == "delivered=1" ? "yellow" : "transparent",
          }}
          href="/myorders?delivered=1"
        >
          Delivered
        </a>
      </div>
      {/* LOADING_INDICATOR */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {/* ✅CONTENT_LOADED{MESSAGE} */}
      <div className="orders-content">
        {!isApiWorking && <InfoBar />}
        <table>
          <tbody>{ResultTableUI()}</tbody>
        </table>
        {isApiWorking && <div className="loading_foot">Loading orders...</div>}
        {/* 🚫CONTENT_EMPTY_INDICATOR */}
        {!isApiWorking && orders.length === 0 && (
          <div className="loading_foot">
            <i className="fa fa-check-square" /> No orders found
          </div>
        )}
      </div>
      {/* 1️⃣PAGINATION */}
      <Pagination
        items={paginationArray}
        pageSize={filterLimit}
        currentPage={getCurrentPage()}
        onChangePage={(index) => onChangePage(index)}
      />
    </div>
  );

  //BUILD_TABLE_DATA
  function ResultTableUI() {
    var tableData = [];
    if (isEmpty(orders)) {
      return;
    }
    orders.forEach((_order, index) => {
      tableData.push(
        <tr
          key={index}
          style={{ fontWeight: _order.in_progress === "1" ? "bold" : "" }}
        >
          <td>
            <div className="order-row">
              {/* ORDERED_FROM_USER */}
              <div className="_header">
                <div className="h-left">
                  <p>Ordered from:</p>
                  <a href={`/user=${_order.from_id}`}>{_order.from}</a>
                </div>
                <div className="h-right">
                  <p>
                    <strong>{convertDate(_order.created_at)}</strong>
                  </p>
                </div>
              </div>
              {/* ORDERED_ITEM_BODY */}
              <div className="body">
                {/* ->ORDER_PROGRESS_STATUS_BAR */}
                <div className="order-progress">
                  {createProgressBar(_order.progress, _order.is_refunded)}
                </div>
                {/* ->ORDERED_ITEM_DETAILS */}
                <div className="order-image">
                  {/* ----->ORDERED_ITEM_IMAGE */}
                  <Zoom>
                    <img src={_order.img_url} alt="" />
                  </Zoom>
                  {/* ----->ORDERED_ITEM_TEXT */}
                  <div className="order-text">{_order.text}</div>
                </div>
                {/* ----->ORDERED_ITEM_ID */}
                <div className="order-id">
                  Order Id: <strong>{_order.id}</strong>
                  {/* ----->ORDERED_ITEM_INVOICE */}
                  <a href={_order.invoice} target="_blank">
                    <i className="fa fa-file-text" />
                    Invoice
                  </a>
                </div>
                <div className="buttons">
                  {/* ADD_TRACKING_NUMBER */}
                  <CopyToClipboard
                    text={_order.tracking_number}
                    onCopy={() => copyTrackingNumber(_order.tracking_number)}
                  >
                    <i className="fa fa-truck" />
                  </CopyToClipboard>
                  <input
                    disabled={props.user.usertype == "2" ? false : true}
                    placeholder={
                      props.user.usertype == "2"
                        ? "Tracking number"
                        : "No tracking number"
                    }
                    value={_order.tracking_number}
                  ></input>
                  {/* CHANGE_ORDER_STATUS_SELECTOR */}
                  <select
                    className="order_status_select"
                    disabled={props.user.usertype == "2" ? false : true}
                    value={_order.status}
                  >
                    <option value={"1"}>Ordered</option>
                    <option value={"2"}>Dispatched</option>
                    <option value={"3"}>Delivered</option>
                    <option value={"4"}>Refunded</option>
                  </select>
                  {props.user.usertype == "2" && <button>Update</button>}
                  {/* SEND_MESSAGE_BUTTON */}
                  <button onClick={() => sendOrderMessage(_order.id)}>
                    Message
                  </button>
                  {/* COPY_TRACKING_NUMBER_BUTTON */}
                  {!isEmpty(_order.tracking_number) && (
                    <CopyToClipboard
                      text={_order.tracking_number}
                      onCopy={() => copyTrackingNumber(_order.tracking_number)}
                    >
                      <button>
                        <i
                          className={
                            _order.tracking_number !== copiedTrackNo
                              ? "fa fa-truck"
                              : "fa fa-check"
                          }
                        />
                        {_order.tracking_number === copiedTrackNo
                          ? "Copied"
                          : "Copy"}
                      </button>
                    </CopyToClipboard>
                  )}
                  {props.user.usertype != "2" && (
                    <button
                      className="mark_as_delivered_btn"
                      onClick={() => markAsDelivered()}
                    >
                      <i className="fa fa-check-circle" />
                      Mark as Delivered
                    </button>
                  )}
                </div>
              </div>
            </div>
          </td>
        </tr>
      );
    });
    return tableData;
  }

  //PROGRESS-BAR
  function createProgressBar(order_progress, is_refunded) {
    var steps = [...Array(3).keys()].map((i) => (
      <Step key={i}>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
            <div className="checked">
              <i
                key={index}
                className={
                  is_refunded === "1" ? "fa fa-circle" : "fa fa-check-circle"
                }
                style={{ color: `${accomplished ? "#00bd3f" : "darkgray"}` }}
              />
            </div>
          </div>
        )}
      </Step>
    ));
    return (
      <div>
        <div className="p-bar">
          <ProgressBar
            percent={order_progress}
            filledBackground={`${
              order_progress === 100 ? "#0aff0250" : "#d3d3d5"
            }`}
          >
            {steps}
          </ProgressBar>
        </div>
        <div className="progress-titles">
          <p style={{ textAlign: "left" }}>Ordered</p>
          <p>Dispatched</p>
          {is_refunded !== "1" && (
            <p style={{ textAlign: "right" }}>Delivered</p>
          )}
          {is_refunded === "1" && (
            <p style={{ textAlign: "right" }}>Refunded</p>
          )}
        </div>
      </div>
    );
  }

  //MARK_ORDER_STATUS_AS_DELIVERED
  function markAsDelivered(order_id, new_status) {
    if (window.confirm("Would you like to mark this order as DELIVERED?")) {
      updateOrderStatus(order_id, new_status);
    }
  }

  //GO-TO: ORDER
  function sendOrderMessage(order_id) {
    window.location.href = `/order=${order_id}`;
  }

  //COPY_TRACKING_NUMBER
  function copyTrackingNumber(tracking_number) {
    setCopiedTrackNo(tracking_number);
  }

  function getCurrentPage() {
    // var _url = window.location.href.split("/")[4];
    //get category_id
    // var currentPaginationNumber = _url
    //   .split("?")[1]
    //   .split("&")[0]
    //   .split("=")[1];
    // console.log("currentPaginationNumber", currentPaginationNumber);
    var currentPaginationNumber = 1;
    return Math.max(1, parseInt(currentPaginationNumber));
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }
}

export default MyOrders;
